import { useCallback, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { Author, DOCUMENT_STATUSES, IDocument, IUser } from '___types'
import { replaceInArray, toBase64 } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

const generateDocument = (author: Author, name: string, parent?: string | null) => {
  const moment = { _seconds: Math.floor(Date.now() / 1000), _nanoseconds: 0 }
  return {
    status: DOCUMENT_STATUSES.FINAL,
    name: name,
    author: { id: author?.id, email: author?.email, firstName: author?.firstName, lastName: author?.lastName, imageUrl: author?.imageUrl },
    parentCategoryId: parent,
    created: moment,
    edited: moment,
    sharingEnabled: false,
    sharedWith: [],
  }
}

export type UploadPDFDocumentVariables = { base64data: string; name: string }
export type UploadPDFDocumentContext = { mutationId: string }
export type UploadPDFDocumentMutationOptions = MutationOptions<IDocument, unknown, UploadPDFDocumentVariables, UploadPDFDocumentContext>
export type UploadPDFDocumentFunctionType = (file: File, options?: UploadPDFDocumentMutationOptions) => Promise<void>
const uploadPDFDocumentMutationFunction = (variables: UploadPDFDocumentVariables) =>
  documentsAPI.uploadPDFDocument(variables.base64data, variables.name)

export const useUploadPDFDocument = () => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: IDocument[] | undefined) => IDocument[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: UploadPDFDocumentVariables) => {
    const author = queryClient.getQueryData([QUERY_KEYS.USER]) as IUser
    const optimisticPayload = { id: mutationId, mutating: true, mutation: 'create' } as IDocument
    const optimisticDocument = Object.assign(generateDocument(author, variables.name), optimisticPayload) as IDocument
    updateListing(data => [optimisticDocument].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: UploadPDFDocumentVariables, context: UploadPDFDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    updateListing((data: IDocument[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (document: IDocument, variables: UploadPDFDocumentVariables, context: UploadPDFDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT, document.id], document)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, document))
  }

  const onSettled = () => {
    queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
  }

  const documentUploadPDFMutation = useMutation<IDocument, unknown, UploadPDFDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, mutationId],
    uploadPDFDocumentMutationFunction,
    { onMutate, onError, onSuccess, onSettled }
  )

  const uploadPDFMutationFunction: UploadPDFDocumentFunctionType = useCallback(
    async (file, options) => {
      const base64data = await toBase64(file)
      return documentUploadPDFMutation.mutate({ base64data, name: file.name }, options)
    },
    [documentUploadPDFMutation]
  )

  return { uploadPDF: uploadPDFMutationFunction, uploadingPDF: documentUploadPDFMutation.isLoading }
}

export default useUploadPDFDocument
