import Upload from './Upload'

import './style.scss'

export type UploadProps = { documentId: string; disabled?: boolean }
export type UploadModalContentProps = { documentId: string }
export type UploadModalContentEmployeCategorySelectProps = { id: string; onSelect: (id: string) => void; value?: string }

export type IntegrationConfig = { active: boolean; employeeId: string; categoryId?: string; share?: boolean; splitId?: string | null }
export type UploadConfig = Record<string, IntegrationConfig> | undefined

export const uploadModalClasses = {
  wrapper: 'Bamboo-Upload-Modal-wrapper',
  employee: 'Bamboo-Upload-Modal-employee',
  integrationCue: 'Bamboo-Upload-Modal-integrationCue',
  switch: 'Bamboo-Upload-Modal-switch',
  details: 'Bamboo-Upload-Modal-details',
  display: 'Bamboo-Upload-Modal-display',
  splits: 'Bamboo-Upload-Modal-splits',
  select: 'Bamboo-Upload-Modal-select',
}
export const uploadClasses = { wrapper: 'Bamboo-Upload-wrapper', modal: uploadModalClasses }

export { Upload }
