import { PropsWithChildren, MouseEventHandler, FocusEventHandler } from 'react'

import Button from './Button'

import './style.scss'

const classes = {
  wrapper: 'Casus-Components-Button-wrapper',
  background: 'Casus-Components-Button-background',
  overlay: 'Casus-Components-Button-overlay',
  button: 'Casus-Components-Button-button',
} as const

export type ButtonProps = PropsWithChildren<{
  id?: string
  className?: string
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  transparent?: boolean
  red?: boolean
  green?: boolean
  blue?: boolean
  orange?: boolean
  yellow?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  tabbable?: boolean
  loading?: boolean
  title?: string
  showSpinner?: boolean
  onClickBlur?: boolean
  noOverlaySVG?: boolean
  noBackgroundSVG?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  onFocus?: FocusEventHandler<HTMLButtonElement>
}>
export type ButtonOverlayProps = {
  loading?: boolean
  borderRadius?: number
}

export { Button, classes }
export default Button
