import documentsAPI, { DOCUMENT_LIST_PER_PAGE } from './api.documents'
import integrationsApi from './api.integrations'
import subscriptionsAPI from './api.subscriptions'
import teamAPI from './api.team'
import templatesAPI, { TEMPLATE_LIST_PER_PAGE } from './api.templates'
import userAPI from './api.user'

export const FILE_NAME_REGEX = new RegExp('(?<=^|;)filename=(?<filename>[^;\\n]*)(?=;|$)')

export { documentsAPI, DOCUMENT_LIST_PER_PAGE }
export { integrationsApi }
export { subscriptionsAPI }
export { teamAPI }
export { templatesAPI, TEMPLATE_LIST_PER_PAGE }
export { userAPI }
