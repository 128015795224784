import firebase from 'firebase/app'
import 'firebase/storage'

import {
  AlternatingArrayRecord,
  CSS_CUSTOM_CLASS_APPENDICES_SPLIT,
  CSS_CUSTOM_CLASS_PROPERTY_MATCH,
  CSS_PROPERTY_VALUE_MATCH_ALL,
  DataStructure,
  KEY_MAP,
  NESTED_STRUCTURE_KEYS,
  NestedStructureKeys,
  RETAIN_KEYS,
  RecursiveRecord,
  SEGMENT_KEYS,
  STYLE_AFFIXES,
  STYLE_DEFAULTS,
  STYLE_MAP,
  STYLE_PROPERTIES,
  StyleMapKeys,
  StylePropertyTypes,
  WizardEditorStylesType,
} from '___types'

const keyMap = { styleName: 'customStyle' } as Record<string, string>
const valueMap = { id: value => String(value), styles: value => value ?? [] } as Record<string, (value: unknown) => any>
export const mapStructureKeys = (structure: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(structure).reduce((result, [key, value]) => {
    const mappedKey = keyMap[key] || key
    const mappedValue = (valueMap[key] && valueMap[key](value)) || value
    const parsedValue = !(NESTED_STRUCTURE_KEYS.includes(mappedKey as NestedStructureKeys) && Array.isArray(structure[key]))
      ? mappedValue
      : (structure[key] as Record<string, unknown>[]).map(inner => mapStructureKeys(inner))
    return Object.assign(result, { [mappedKey]: parsedValue })
  }, {})

type ExtractStylesStructureType = AlternatingArrayRecord<string, unknown> & { customStyle: string; styles: string[] }
const extractStyles = (structure: ExtractStylesStructureType): string[] =>
  NESTED_STRUCTURE_KEYS.reduce(
    (result, key) => {
      const potentialArray = structure[key] as ExtractStylesStructureType[]
      const innerStyles = Array.isArray(potentialArray)
        ? potentialArray?.reduce((accumulated, current) => accumulated.concat(extractStyles(current)), [] as string[])
        : []
      return innerStyles?.length ? result.concat(innerStyles) : result
    },
    [structure.customStyle].concat(structure.styles).filter(style => style)
  )

export const parseStyles = (cssData: RecursiveRecord<string, string>, dataStructure: DataStructure) => {
  const givenStyles = Object.keys(cssData.customStyles)
  const styles = givenStyles.concat(extractStyles(dataStructure as unknown as ExtractStylesStructureType)).concat(Object.keys(STYLE_MAP))
  const uniqueStyles = Array.from(new Set(styles))
  const dynamicStyles = uniqueStyles.reduce(
    (result, current) => {
      const splitString = current.split('-')
      const mappedStyleArray = splitString
        .reduce(
          (resultingArray, string) => {
            const joined = [resultingArray.pop(), string].filter(string => string).join('-')
            const mapped = STYLE_MAP[joined as StyleMapKeys]
            return resultingArray.concat(mapped ? [mapped, ''] : joined)
          },
          ['']
        )
        .filter(string => string)
        .join('-')
        .split(' ')
      mappedStyleArray.forEach(mappedStyle => {
        const mappedStringArray = mappedStyle.split(new RegExp('(?<!\\\\)-', 'gs'))
        //@ts-ignore
        const value = mappedStringArray.length > 1 ? mappedStringArray.pop()!.replaceAll('__', ' ').replaceAll('\\-', '-') : ''
        const property = mappedStringArray.join('-') as StylePropertyTypes
        const defaultValue = STYLE_DEFAULTS[property]
        const style = result[property]
        if (style && (value || defaultValue)) style.push(value || defaultValue)
      })
      return result
    },
    Object.keys(STYLE_PROPERTIES).reduce(
      (emptyStyles, style) => Object.assign(emptyStyles, { [style]: [] }),
      {} as Record<StylePropertyTypes, string[]>
    )
  )
  return Object.entries(dynamicStyles).reduce((result, [style, valueArray]) => {
    valueArray.forEach(value => {
      //@ts-ignore
      const propertyName = `${style}-${value.replaceAll(' ', '__')}`
      const propertyValueArray = [value]
      const { prefix, suffix, affixCondition } = STYLE_AFFIXES[style]
      if (typeof affixCondition === 'function' ? affixCondition(value) : affixCondition) {
        if (prefix) propertyValueArray.unshift(prefix)
        if (suffix) propertyValueArray.push(suffix)
      }
      Object.assign(result, { [propertyName]: { [STYLE_PROPERTIES[style]]: propertyValueArray.join('') } })
    })
    return result
  }, {} as WizardEditorStylesType)
}

const RESULTING_STYLES_CUSTOM_STYLES = 'customStyles'
const RESULTING_STYLES_CUSTOM_PROPERTIES = 'customProperties'
const RESULTING_STYLES = {
  [RESULTING_STYLES_CUSTOM_STYLES]: {} as RecursiveRecord<string, string>,
  [RESULTING_STYLES_CUSTOM_PROPERTIES]: {} as RecursiveRecord<string, string>,
} as const
type ResultingStylesType = typeof RESULTING_STYLES
type ResultingStylesKey = keyof ResultingStylesType

const applyStyleObject = (targetObject: RecursiveRecord<string, string>, styleObject: Record<string, string>, ...nestPath: string[]): void => {
  if (!(Object.entries(styleObject)?.length && nestPath.length)) return
  const key = nestPath.shift()!
  const existing = targetObject[key] || {}
  if (typeof existing === 'string') return
  if (nestPath.length) applyStyleObject(existing, styleObject, ...nestPath)
  Object.assign(targetObject, { [key]: nestPath.length ? existing : Object.assign(existing, styleObject) })
}

const parseCustomStyle = (customStyle: string): [ResultingStylesKey, ...string[]] => {
  const split = customStyle
    .replace(/\s+/g, '')
    .split(CSS_CUSTOM_CLASS_APPENDICES_SPLIT)
    .map(string => string.trim())
  const customClassProperty = split.shift() || ''
  const { isCustomClass, customString } = customClassProperty.match(CSS_CUSTOM_CLASS_PROPERTY_MATCH)?.groups || {}
  const key = (isCustomClass && RESULTING_STYLES_CUSTOM_STYLES) || RESULTING_STYLES_CUSTOM_PROPERTIES
  return (customString ? [key, customString].concat(split) : []) as [ResultingStylesKey, ...string[]]
}

const CSS_PROPERTY_MAP = { 'margin-left': '--indent-left', 'margin-right': '--indent-right' } as const
type CSSPropertyMapKey = keyof typeof CSS_PROPERTY_MAP
export const parseCssData = (cssData: Record<string, string>): ResultingStylesType =>
  Object.entries(cssData).reduce(
    (accumulated, [customStyle, styleString]) => {
      const styleObject = Array.from(styleString.matchAll(CSS_PROPERTY_VALUE_MATCH_ALL) || []).reduce((result, { groups }) => {
        const property = (CSS_PROPERTY_MAP[groups!.property as CSSPropertyMapKey] || groups!.property).trim()
        const value = groups!.value.trim()
        return Object.assign(result, { [property]: value })
      }, {})
      const [key, ...nestPath] = parseCustomStyle(customStyle)
      if (key) applyStyleObject(accumulated[key], styleObject, ...nestPath)
      return accumulated
    },
    { customStyles: {}, customProperties: {} }
  )

export const uploadBlobToFirebaseStorage = async (path: string, blob: Blob) => {
  if (!blob) return
  const ref = firebase.storage().ref().child(path)
  return ref.put(blob).catch(error => console.error('Error:', error))
}

export const getImageSrcURL = async (path: string) => {
  return // temporarily disabled - investigae a better way of fetching image urls
  // return firebase.storage().ref().child(path).getDownloadURL() as Promise<string>
}

const unparseNestedStructureKey = (structure: Record<NestedStructureKeys, unknown[]>, key: NestedStructureKeys) =>
  structure[key]?.length ? { [key]: structure[key].map(segment => unparseDataStructure(segment as Record<string, unknown>)) } : {}
const unparseStyleString = (style: string) => {
  let property = ''
  let value = ''
  const foundMap = Object.entries(STYLE_MAP).find(entry => {
    if (entry[1] === style) return (property = entry[0])
    if (entry[1] === style.split('-').slice(0, -1).join('-')) {
      value = property = entry[0]
      return style.split('-').slice(-1)
    }
    return false
  })
  if (!foundMap) return style
  return [property, value].filter(s => s).join('-')
}
const UNPARSE_MAP_KEYS = { STYLES: 'styles', ID: 'id' } as const
const UNPARSE_MAP = {
  [UNPARSE_MAP_KEYS.STYLES]: (structure: Record<string, unknown>, key: string) =>
    (structure[key] as string[]).map(styleString => unparseStyleString(styleString)),
  [UNPARSE_MAP_KEYS.ID]: (structure: Record<string, unknown>, key: string) => Number(structure[key]),
} as const
const mapDataStructureKey = (key: string) => (key && KEY_MAP[key as keyof typeof KEY_MAP]) || key
const unparseStructureKey = (structure: Record<string, unknown>, key: string) => {
  if (!(key === 'tag' || NESTED_STRUCTURE_KEYS.includes(key as NestedStructureKeys)) && structure[key]) return
  const mappedValue = UNPARSE_MAP[key as keyof typeof UNPARSE_MAP] ? UNPARSE_MAP[key as keyof typeof UNPARSE_MAP](structure, key) : structure[key]
  return { [mapDataStructureKey(key)]: mappedValue }
}
export const unparseDataStructure = (structure: Record<string, unknown>): Record<string, unknown> => {
  const { type } = structure
  const generalTypeKeys = SEGMENT_KEYS.ALL as unknown as string[]
  const specificTypeKeys = (SEGMENT_KEYS[type as keyof typeof SEGMENT_KEYS] || []) as unknown as string[]
  const stripKeys = generalTypeKeys.concat(specificTypeKeys).concat(RETAIN_KEYS as unknown as string[])
  const stripped = stripKeys.reduce((acc, key) => Object.assign(acc, unparseStructureKey(structure, key)), {})
  return NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) => Object.assign(acc, unparseNestedStructureKey(structure as Record<NestedStructureKeys, unknown[]>, key)),
    stripped
  )
}
