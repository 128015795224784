import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { BREAK_TAG_MATCH, SECURITY_LEVELS, SignatureSecurityLevel, Signee } from '___types'
import { Cogwheel } from 'assets/svgIconComponents'
import { Button, CheckBox, Input, ModalContent as SignatureModalContent } from 'components/CasusComponents'
import useModal from 'Modal'
import { DocumentPublishingModalContentProps, DocumentPublishingModalContentClasses as classes } from '.'

export const ModalContent: FunctionComponent<DocumentPublishingModalContentProps> = React.memo(({ conclude, context, splits }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })
  const [
    includeEmail,
    // setIncludeEmail
  ] = useState(context?.includeEmail ?? false)
  const [previewAvailable, setPreviewAvailable] = useState(context?.previewAvailable ?? false)
  const [signatureAvailable, setSignatureAvailable] = useState(context?.signatureAvailable ?? false)
  const [expires, setExpires] = useState(context?.expires ?? false)
  const [expirationTime, setExpirationTime] = useState(context?.expirationTime ?? DateTime.now().toISO())
  // const [singleUse, setSingleUse] = useState(context?.singleUse ?? false)
  const [split, setSplit] = useState(context?.split ?? null)

  const [input, setInput] = useState<HTMLInputElement>()
  const ref = useCallback(node => node && setInput(node), [])

  const open = useModal()

  const signeeCount = useMemo(() => (context.signatureConfig?.signees?.length || 0) + Number(includeEmail), [context, includeEmail])
  const securityLevelText = useMemo(() => {
    switch (context.signatureConfig?.security) {
      case SECURITY_LEVELS.BASE:
        return 'Basic'
      case SECURITY_LEVELS.HIGH:
        return 'High'
      case SECURITY_LEVELS.MAX:
        return 'Maximum'
      default:
        return 'Basic'
    }
  }, [context])

  const splitRender = useMemo(() => {
    if (!Object.keys(splits || {}).length) return null
    const splitButtons = [[null, translate('full-document')]].concat(Object.entries(splits || {})).map(
      ([splitId, splitLabel]) => (
        <Button onClick={() => setSplit(splitId)} dataSet={{ active: split === splitId ? '' : undefined }} disabled={split === splitId}>
          {splitLabel}
        </Button>
      ),
      []
    )
    return <div className={classes.splits.wrapper}>{splitButtons}</div>
  }, [splits, translate, split])

  const onConclude = useCallback(
    (innerContext: Record<string, unknown> | undefined, ...params: unknown[]) => {
      const [security, signees, messageInput] = params as [SignatureSecurityLevel, Signee[], HTMLPreElement]
      const textarea = document.createElement('textarea')
      //@ts-ignore
      textarea.innerHTML = messageInput.innerHTML.replaceAll(BREAK_TAG_MATCH, '\n')
      const message = textarea.value
      textarea.remove()
      if (innerContext) Object.assign(innerContext, { security, signees, message })
      Object.assign(context, { signatureAvailable: true })
    },
    [context]
  )

  const openModal = useCallback(() => {
    if (context)
      Object.assign(context, {
        notifyEmail: input?.value,
        includeEmail,
        previewAvailable,
        signatureAvailable,
        expires,
        expirationTime,
      })
    open({
      context: context.signatureConfig,
      header: 'Configure signature',
      content: (...params) => <SignatureModalContent conclude={params[2]} context={params[3]} />,
      onConclude,
      footer: false,
    })
  }, [context, input, includeEmail, previewAvailable, signatureAvailable, expires, expirationTime, open, onConclude])

  return (
    <div className={classes.wrapper}>
      {splitRender}
      <div className={classes.section} data-active={signatureAvailable ? '' : undefined}>
        <div className={classes.sectionColumn}>
          <CheckBox value={signatureAvailable} onChange={setSignatureAvailable} onClickBlur />
        </div>
        <div className={classes.sectionColumn}>
          <span className={classes.sectionPrimary}>Request Signatures</span>
          <span className={classes.sectionSecondary}>
            {securityLevelText} Security Signature {signeeCount ? `[${signeeCount}]` : ''}
          </span>
        </div>
        <div className={classes.sectionColumn}>
          <Button onClick={openModal}>
            <Cogwheel />
            Configure
          </Button>
        </div>
      </div>
      <div className={[classes.section, classes.expirationSection].join(' ')} data-active={expires ? '' : undefined}>
        <div className={classes.sectionColumn}>
          <CheckBox value={expires} onChange={setExpires} onClickBlur />
        </div>
        <div className={classes.sectionColumn}>
          <span className={classes.sectionPrimary}>Time Restricted</span>
          <span className={classes.sectionSecondary}></span>
        </div>{' '}
        <div className={classes.sectionColumn} data-active={signatureAvailable ? '' : undefined}>
          <Input
            type="date-time"
            value={expirationTime as string}
            options={{ inputTime: true }}
            onInput={event => {
              setExpirationTime(DateTime.fromFormat((event.target as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm").toISO()!)
              setExpires(true)
            }}
          />
        </div>
      </div>
      <div className={classes.section} data-active={!previewAvailable ? '' : undefined}>
        <div className={classes.sectionColumn}>
          <CheckBox value={!previewAvailable} onChange={value => setPreviewAvailable(!value)} onClickBlur />
        </div>
        <div className={classes.sectionColumn}>
          <span className={classes.sectionPrimary}>Show questionnaire only</span>
          <span className={classes.sectionSecondary}>Disable document preview</span>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionColumn}>
          <span className={classes.sectionPrimary}>Email notification</span>
          <span className={classes.sectionSecondary}>Inform people about publishing</span>
          <Input ref={ref} type="text" defaultValue={context.notifyEmail || ''} placeholder="Enter email" options={{ format: 'email' }} />
          {/* <CheckBox value={includeEmail} onChange={setIncludeEmail} label="Include in signature" onClickBlur /> */}
        </div>
      </div>
      <Button
        onClick={() => {
          const email = (input as HTMLInputElement).value
          // if (!input?.validity.valid) return input?.focus()
          // if (includeEmail && !input?.value) return input?.focus()
          if (!input?.validity.valid || !input?.value) return input?.focus()
          const payload = {
            notifyEmail: email,
            includeEmail,
            previewAvailable,
            signatureAvailable,
            expires,
            expirationTime,
            split,
          }
          conclude(payload)
        }}
        emphasized
      >
        {translate('publish')}
      </Button>
    </div>
  )
})

ModalContent.displayName = 'Casus-Components-Publishing-Document-ModalContent'

export default ModalContent
