import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
// import { hotjar } from 'react-hotjar'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental'
import getStore from './redux/store'
import App from './App'
import AppThemeProvider from 'config/themes/AppThemeProvider'
import { ConfirmPopupProvider } from 'providers/ConfirmPopupProvider'
// import { isProd } from 'config'
import './index.css'
import './utils/other/i18n/i18n'
import { StoreProvider } from '___store'
import { ModalContextProvider } from 'Modal'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /* onError: async (err: any) => {
        const error = err?.response?.data
        if (error?.status === 401 && (error?.error_code === 'E0007' || error?.error_code === 'E0017')) {
          return (window.location.href = '/logout')
        }
      }, */
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_IO_DSN,
  environment: process.env.REACT_APP_FIREBASE_PROJECT_ID,
})

if (window.location.pathname.endsWith('/employee')) {
  localStorage.removeItem('casusToken')
}

export const localStoragePersistor = createLocalStoragePersistor()

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
})

const {
  store,
  // persistor
} = getStore()

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

if (trackingId && trackingId !== 'missing') {
  ReactGA.initialize(trackingId)
}

// legacy
/* if (isProd) {
  const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID
  const HOTJAR_SNIPPET_VERSION = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
  if (HOTJAR_ID && HOTJAR_SNIPPET_VERSION) {
    hotjar.initialize(+HOTJAR_ID, +HOTJAR_SNIPPET_VERSION)
  }
} */

ReactDOM.render(
  <Suspense fallback=" ">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <AppThemeProvider>
            <ConfirmPopupProvider>
              <StoreProvider>
                <ModalContextProvider>
                  <App />
                </ModalContextProvider>
              </StoreProvider>
            </ConfirmPopupProvider>
          </AppThemeProvider>
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </QueryClientProvider>
  </Suspense>,
  document.getElementById('root')
)
