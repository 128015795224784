import { useQuery } from 'react-query'

import { integrationsApi } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

export const useFetchBambooEmployeeCategories = (id?: string | null) => {
  const bambooEmployeeCategoriesQuery = useQuery({
    queryKey: [QUERY_KEYS.BAMBOO_EMPLOYEE_CATEGORIES, id],
    queryFn: () => integrationsApi.getBambooEmployeeCategories(id),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  })
  return bambooEmployeeCategoriesQuery
}

export default useFetchBambooEmployeeCategories
