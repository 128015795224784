import { CUSTOM_TEXT_REGEX_STRING, NUMBER_REGEX_STRING, OPERATION_REGEX_STRING, extractPropertiesFromCustomText } from '___types'

const operand = `(${CUSTOM_TEXT_REGEX_STRING}|${NUMBER_REGEX_STRING})`
const fragment = `(${operand}${OPERATION_REGEX_STRING})`
const schema = `${OPERATION_REGEX_STRING}${fragment}*`

type Fragment = { content: (string | Fragment)[]; error: boolean }

export const parseCalculation = (calculation: string) =>
  `(${calculation})`.split(/(?=\()|(?<=\))/g).reduce(
    (result: Fragment[], fragment: string) => {
      //   console.log('FRAGMENT: ', fragment)
      if (result[result.length - 1].error) return result
      const { fragmentStart, fragmentEnd } = fragment.match(/(?<fragmentStart>^\()?[^()]*(?<fragmentEnd>\)$)?/)?.groups || {}
      const content = fragment.slice(0 + Number(Boolean(fragmentStart)), fragment.length - Number(Boolean(fragmentEnd)))
      const parsed = `${content.slice(0, 1) === '-' && fragmentStart ? '0' : ''}${content}`
      //   console.log('PARSED: ', parsed)
      let regexString = `${fragmentStart ? operand : ''}${schema}${fragmentEnd ? operand : ''}`
      const xor = (fragmentStart && fragmentEnd) !== (fragmentStart || fragmentEnd)
      if (xor) regexString = `(${regexString})?`
      regexString = `^${regexString}`
      regexString += '$'
      const validityRegex = new RegExp(regexString)
      const error = !parsed.match(validityRegex)
      if (fragmentStart) {
        const newFragment = { content: [], error: false }
        result[result.length - 1].content.push(newFragment)
        result.push(newFragment)
      }
      const splitIndices = Array.from(parsed.matchAll(new RegExp(operand, 'g')))
        .map(matchArray => [matchArray.index!, matchArray.index! + matchArray[0].length])
        .flat()
      splitIndices.unshift(0)
      const splitContent = splitIndices
        .reduce((result, index, i) => result.concat(parsed.substring(index, splitIndices[i + 1])), [] as string[])
        .filter(s => s)
      const lastNestedFragment = result[result.length - 1]
      lastNestedFragment.content = lastNestedFragment.content.concat(splitContent)
      lastNestedFragment.error = lastNestedFragment.error || error

      if (fragmentEnd) {
        lastNestedFragment.content = lastNestedFragment.content.filter(c => c)
        if (result.length > 1) {
          result.pop()
          result[result.length - 1].error = result[result.length - 1].error || lastNestedFragment.error
        } else result[result.length - 1].error = true
      }
      return result
    },
    [{ content: [], error: false }] as Fragment[]
  )

const OPERATIONS = {
  '^': (a: string | number, b: string | number) => Math.pow(Number(a), Number(b)),
  '*': (a: string | number, b: string | number) => Number(a) * Number(b),
  '/': (a: string | number, b: string | number) => Number(a) / Number(b),
  '+': (a: string | number, b: string | number) => Number(a) + Number(b),
  '-': (a: string | number, b: string | number) => Number(a) - Number(b),
} as const
const PEMDAS = [['^'], ['*', '/'], ['+', '-']]

const evaluateCalculationContent = (content: (string | Fragment | number[])[], references: References) => {
  const parsedContent = content.slice().map(entry => (typeof entry === 'string' ? entry.trim() : entry))
  let parenthesesContentIndex = parsedContent.findIndex(entry => typeof entry === 'object' && !Array.isArray(entry))
  while (parenthesesContentIndex !== -1) {
    const result = evaluateCalculationContent((parsedContent[parenthesesContentIndex] as Fragment).content, references)
    parsedContent.splice(parenthesesContentIndex, 1, result)
    parenthesesContentIndex = parsedContent.findIndex(entry => typeof entry === 'object' && !Array.isArray(entry))
  }
  return PEMDAS.reduce((collapsed, operationArray) => {
    // console.log('PRE COLLAPSE: ', JSON.parse(JSON.stringify(collapsed)))
    // console.log('OPERATION: ', operationArray.join(''))
    let operationIndex = collapsed.findIndex(entry => operationArray.includes(entry as string))
    while (operationIndex !== -1) {
      const index = operationIndex
      const operands = new Array(2).fill(undefined).map((_, i) => {
        const result = [] as (string | number)[]
        const value = collapsed[index + Math.pow(-1, i + 1)]
        const { sourceIndex, valueIndex } = extractPropertiesFromCustomText(String(value), 'reference')
        const { start = 0, end = 1 } = valueIndex?.match(/^(?<start>[0-9]+)(-(?<end>[0-9]+))?$/)?.groups || {}
        const valueIndexRange = [Number(start), end > start ? Number(end) : Number(start) + 1]
        if (sourceIndex) return result.concat(references[Number(sourceIndex)]?.slice(...valueIndexRange) ?? [])
        return result.concat(typeof value === 'string' ? Number(value) : value)
      }) as [number[], number[]]
      const operationResult = operands[0].reduce(
        (result, operandA) => result.concat(operands[1].map(operandB => OPERATIONS[collapsed[index] as keyof typeof OPERATIONS](operandA, operandB))),
        [] as number[]
      )
      collapsed.splice(operationIndex - 1, 3, operationResult)
      operationIndex = collapsed.findIndex(entry => operationArray.includes(entry as string))
    }
    // console.log('POST COLLAPSE: ', JSON.parse(JSON.stringify(collapsed)))
    return collapsed
  }, parsedContent as (string | number[])[])[0] as number[]
}

type References = number[][]

export const calculate = (parsedCalculation: Fragment[], references: References) => {
  if (Array.isArray(parsedCalculation) && parsedCalculation.length === 1 && parsedCalculation[0].content && !parsedCalculation[0].error)
    return evaluateCalculationContent(parsedCalculation[0].content, references)
  return [NaN]
}

// -1-(-2^3-(-4*5/6))+7/8*9+10*(-11/12*13-14+15/(16/17)+18-(19/20)) = 82.25
