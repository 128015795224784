import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { SECURITY_LEVELS } from '___types'
import { useDocument } from '___hooks'
import { Public } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import { DocumentPublishingModalContentContext, DocumentPublishingProps, classes } from '.'
import ModalContent from './Document.ModalContent'

const defaultSettings = {
  notifyEmail: '',
  includeEmail: false,
  previewAvailable: false,
  signatureAvailable: false,
  signatureConfig: { concludeText: 'Save', concludeDisabled: false, security: SECURITY_LEVELS.BASE, signees: [], message: '' },
  expires: false,
  expirationTime: DateTime.now().toISO(),
  singleUse: false,
  split: null,
  language: null,
}

export const Publishing: FunctionComponent<DocumentPublishingProps> = React.memo(({ id }) => {
  const {
    t: translate,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })
  const { data: { signatureSecurityLevel, publicSettings, isPublic } = {}, template, publish, publishing } = useDocument(id)
  const { data: { splits } = {} } = template
  const defaultContext = useMemo(
    () =>
      Object.assign({}, defaultSettings, publicSettings, {
        signatureConfig: Object.assign({}, defaultSettings.signatureConfig, publicSettings?.signatureConfig, { security: signatureSecurityLevel }),
      }),
    [signatureSecurityLevel, publicSettings]
  )
  const context = useRef(JSON.parse(JSON.stringify(defaultContext)) as DocumentPublishingModalContentContext)
  const open = useModal({
    header: 'Extern teilen',
    close: false,
    content: (...params) => <ModalContent conclude={params[2]} context={params[3] as DocumentPublishingModalContentContext} splits={splits} />,
    context: context.current,
    onClose: context => Object.assign(context, JSON.parse(JSON.stringify(defaultContext))),
    onConclude: (context, settings) => {
      const { notifyEmail, includeEmail, previewAvailable, signatureAvailable, signatureConfig, expires, expirationTime, singleUse, split } =
        Object.assign(context, settings) as DocumentPublishingModalContentContext
      publish(notifyEmail, includeEmail, previewAvailable, signatureAvailable, signatureConfig, expires, expirationTime, singleUse, split, language)
    },
    footer: false,
  })

  useEffect(() => {
    Object.assign(context.current, publicSettings, {
      signatureConfig: Object.assign({}, context.current.signatureConfig, publicSettings?.signatureConfig),
    })
  }, [publicSettings])

  return (
    <Button className={classes.wrapper} onClick={() => open()} onClickBlur disabled={publishing} loading={publishing}>
      <Public />
      {translate(isPublic ? 'republish' : 'publish')}
    </Button>
  )
})

Publishing.displayName = 'Casus-Components-Publishing-Document'

export default Publishing
