import { MutateOptions, useMutation, useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'

import { DOCUMENT_STATUSES, IDocument, ITemplate, SECURITY_LEVELS, Segment, SignatureSecurityLevel, Signee } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { parseAnswersForDownload } from '.'
import { useCallback } from 'react'

// ======================================================= //
// ======================= REGULAR ======================= //
// ======================================================= //
export type RequestRegularDocumentSignatureVariables = {
  id: string
  paragraphs?: Segment[]
  securityLevel: SignatureSecurityLevel
  signees: Signee[]
  message?: string
  splitId?: string | null
  publicFlow?: boolean
}
export type RequestRegularDocumentSignatureContext = { mutationId: string }
export type RequestRegularDocumentSignatureMutationOptions = MutateOptions<
  IDocument,
  unknown,
  RequestRegularDocumentSignatureVariables,
  RequestRegularDocumentSignatureContext
>
export type RequestRegularDocumentSignatureFunctionType = (
  signees: Signee[],
  securityLevel?: SignatureSecurityLevel,
  message?: string,
  splitId?: string | null,
  options?: RequestRegularDocumentSignatureMutationOptions
) => void
// ======================================================= //

// ======================================================= //
// ========================= PDF ========================= //
// ======================================================= //
export type RequestPDFDocumentSignatureVariables = { id: string; securityLevel: SignatureSecurityLevel; signees: Signee[]; message?: string }
export type RequestPDFDocumentSignatureContext = { mutationId: string }
export type RequestPDFDocumentSignatureMutationOptions = MutateOptions<
  IDocument,
  unknown,
  RequestPDFDocumentSignatureVariables,
  RequestPDFDocumentSignatureContext
>
export type RequestPDFDocumentSignatureFunctionType = (
  signees: Signee[],
  securityLevel?: SignatureSecurityLevel,
  message?: string,
  options?: RequestPDFDocumentSignatureMutationOptions
) => void
// ======================================================= //

const requestRegularDocumentSignatureMutationFunction = (variables: RequestRegularDocumentSignatureVariables) =>
  documentsAPI.requestRegularDocumentSignature(
    variables.id,
    variables.paragraphs!,
    variables.securityLevel,
    variables.signees,
    variables.message,
    variables.splitId,
    variables.publicFlow
  )
const requestPDFDocumentSignatureMutationFunction = (variables: RequestRegularDocumentSignatureVariables) =>
  documentsAPI.requestPDFDocumentSignature(
    variables.id,
    variables.securityLevel,
    // variables.legislation,
    variables.signees,
    variables.message
  )

export const useRequestRegularDocumentSignature = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentQueryKey = [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: RequestRegularDocumentSignatureVariables | RequestPDFDocumentSignatureVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, {
        status: DOCUMENT_STATUSES.LOCKED,
        mutationId,
        mutating: true,
        mutation: 'update',
        original: originalDocument,
      })
      queryClient.setQueryData(documentQueryKey, optimisticDocument)
      if (!publicFlow) updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (
    error: unknown,
    variables: RequestRegularDocumentSignatureVariables | RequestPDFDocumentSignatureVariables,
    context: RequestRegularDocumentSignatureContext | RequestPDFDocumentSignatureContext | undefined
  ) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries(documentQueryKey)
      queryClient.resetQueries(documentQueryKey)
      // queryClient.setQueryData(documentQueryKey, currentDocument.original)
      if (!publicFlow) {
        queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
        // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
      }
    }
  }

  const onSuccess = (
    document: IDocument,
    variables: RequestRegularDocumentSignatureVariables | RequestPDFDocumentSignatureVariables,
    context: RequestRegularDocumentSignatureContext | RequestPDFDocumentSignatureContext | undefined
  ) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData(documentQueryKey, document)
    if (!publicFlow)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
    queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
  }

  const documentRegularSignatureRequestMutation = useMutation<
    IDocument,
    unknown,
    RequestRegularDocumentSignatureVariables,
    RequestRegularDocumentSignatureContext
  >([QUERY_KEYS.DOCUMENT_SIGNATURE_REQUEST, id, 'regular'].concat(publicFlow ? 'public' : []), requestRegularDocumentSignatureMutationFunction, {
    onMutate,
    onError,
    onSuccess,
  })
  const documentPDFSignatureRequestMutation = useMutation<
    IDocument,
    unknown,
    RequestPDFDocumentSignatureVariables,
    RequestPDFDocumentSignatureContext
  >([QUERY_KEYS.DOCUMENT_SIGNATURE_REQUEST, id, 'pdf'].concat(publicFlow ? 'public' : []), requestPDFDocumentSignatureMutationFunction, {
    onMutate,
    onError,
    onSuccess,
  })

  const requestRegularSignatureMutationFunction: RequestRegularDocumentSignatureFunctionType = useCallback(
    (signees, securityLevel, message, splitId, options) => {
      const document = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original?: IDocument }
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, document?.templateId].concat(publicFlow ? 'public' : [])) as ITemplate
      if (!(document && template)) return
      const { answers, languages, integrations } = document
      const [paragraphs, headers, footers] = parseAnswersForDownload(template!, languages, integrations, splitId, answers)
      const payload = { storageId: template.dataStructure.storageId, paragraphs }
      if (headers?.length) Object.assign(payload, { headers })
      if (footers?.length) Object.assign(payload, { footers })
      return documentRegularSignatureRequestMutation.mutate(
        {
          id: id!,
          paragraphs,
          securityLevel: securityLevel || (document?.signatureSecurityLevel ?? SECURITY_LEVELS.BASE),
          signees,
          message,
          splitId,
          publicFlow,
        },
        options
      )
    },
    [queryClient, documentQueryKey, documentRegularSignatureRequestMutation, id, publicFlow]
  )
  const requestPDFSignatureMutationFunction: RequestPDFDocumentSignatureFunctionType = useCallback(
    (signees, securityLevel, message, options) => {
      const document = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original?: IDocument }
      return documentPDFSignatureRequestMutation.mutate(
        { id: id!, securityLevel: securityLevel || (document?.signatureSecurityLevel ?? SECURITY_LEVELS.BASE), signees, message },
        options
      )
    },
    [queryClient, documentQueryKey, documentPDFSignatureRequestMutation, id]
  )

  return {
    requestRegularSignature: requestRegularSignatureMutationFunction,
    requestPDFSignature: requestPDFSignatureMutationFunction,
    requestingSignature: documentRegularSignatureRequestMutation.isLoading || documentPDFSignatureRequestMutation.isLoading,
  }
}

export default useRequestRegularDocumentSignature
