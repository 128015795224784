import React, { FunctionComponent, useState, useMemo, useCallback, useEffect, Dispatch, SetStateAction, RefCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, {
  ChooseIntegrationEntryAction,
  WizardIntegrationsSelector,
  WizardIntegrationEntriesSelector,
  UpdateIntegrationFieldValuesAction,
  ResetIntegrationFieldValuesAction,
} from '___store'

import { useFetchExternalServiceEntries, useFetchExternalServiceEntry } from '___queries'
import { Cogwheel, Spinner } from 'assets/svgIconComponents'
import { Button, Select, Input } from 'components/CasusComponents'
import {
  WizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceProps,
  wizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceClasses as classes,
} from 'Layouts/WizardLayout'

const INPUT_TYPE_MAP = {
  text: 'multiline',
  date: 'date-time',
  number: 'number',
  // currency: 'currency'
} as const

type UseStoreHookResultType = {
  wizardIntegrations: WizardIntegrationsSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  chooseIntegrationEntry: ChooseIntegrationEntryAction
  updateIntegrationFieldValues: UpdateIntegrationFieldValuesAction
  resetIntegrationFieldValues: ResetIntegrationFieldValuesAction
}

export const Instance: FunctionComponent<WizardLayoutLeftPaneDocumentConfigurationIntegrationsInstanceProps> = React.memo(
  ({ id, integrationId, title, fields, loadingCallback }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })
    const { wizardIntegrations, wizardIntegrationEntries, chooseIntegrationEntry, updateIntegrationFieldValues, resetIntegrationFieldValues } =
      useStore(
        'selectWizardIntegrations',
        'selectWizardIntegrationEntries',
        'chooseIntegrationEntry',
        'updateIntegrationFieldValues',
        'resetIntegrationFieldValues'
      ) as UseStoreHookResultType
    const [expanded, setExpanded] = useState(false)

    const [wrapper, setWrapper]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
    const wrapperRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setWrapper(node), [])

    const selectedEntry = useMemo(() => (wizardIntegrationEntries && wizardIntegrationEntries[id]) || '', [wizardIntegrationEntries, id])

    const { data } = useFetchExternalServiceEntries(integrationId)
    const fieldIds = useMemo(() => fields.reduce((result, { id: fieldId }) => result.concat(fieldId ?? []), [] as string[]).join('; '), [fields])
    const { data: entry, isLoading: entryLoading } = useFetchExternalServiceEntry(integrationId, selectedEntry, fieldIds)

    const options = useMemo(
      () =>
        (data?.entries || []).reduce(
          (result, entry) =>
            Object.assign(result, {
              [entry.id]: (data?.displayFields || []).reduce((result, field) => result.concat(entry[field] || []), [] as string[]).join(' '),
            }),
          {}
        ),
      [data]
    ) as Record<string, string>

    const entryFields = useMemo(() => {
      if (!entry) return null
      const storeFields = (wizardIntegrations && wizardIntegrations[id]?.fields) || []
      return fields.map(({ id: fieldId, name, type }) => {
        const inputType = INPUT_TYPE_MAP[type as keyof typeof INPUT_TYPE_MAP] || 'multiline'
        const storeValue = storeFields?.find(({ id: storeFieldId }) => storeFieldId === fieldId)?.value
        //@ts-ignore
        const value = storeValue || entry[fieldId]
        return (
          <div key={`${id}-${entry.id}-${fieldId}`} className={classes.entries.field} data-label={fieldId}>
            <Input id={fieldId} type={inputType} defaultValue={value} placeholder={name} />
          </div>
        )
      })
    }, [fields, wizardIntegrations, entry, id])

    useEffect(() => {
      if (!wizardIntegrations?.[id]?.fields.reduce((result, field) => result.concat(field.value ?? []), [] as string[]).length && entry) {
        updateIntegrationFieldValues({ [id]: fieldIds.split('; ').reduce((result, id) => Object.assign(result, { [id]: entry?.[id] }), {}) })
      }
    }, [wizardIntegrations, entry, updateIntegrationFieldValues, id, fieldIds])

    useEffect(() => {
      loadingCallback(id, entryLoading)
    }, [loadingCallback, id, entryLoading])

    return (
      <div id={id} ref={wrapperRef} className={classes.wrapper} data-expanded={expanded ? '' : undefined}>
        <span className={classes.title}>{title}</span>
        <div className={classes.actions}>
          <Select
            options={options}
            value={selectedEntry}
            placeholder={translate(`select-integration-entry`)}
            searchable
            onSelect={entryId => {
              chooseIntegrationEntry({ integrationId: id, entryId: entryId as string })
              resetIntegrationFieldValues([id])
            }}
          />
          <Button
            onClick={() => {
              if (!expanded && wrapper) setTimeout(() => wrapper.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 0)
              setExpanded(prev => !prev)
            }}
            disabled={!entry}
          >
            {entryLoading ? <Spinner /> : <Cogwheel />}
          </Button>
        </div>
        <div className={classes.entries.wrapper}>{entryFields}</div>
      </div>
    )
  }
)

Instance.displayName = 'WizardLayout-LeftPane-Document-Configuration-Integrations-Instance'

export default Instance
