import { useCallback } from 'react'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument, ITemplate } from '___types'
import { integrationsApi } from '___api'
import { QUERY_KEYS } from '___queries'
import { parseAnswersForDownload } from '../documents'

export type UploadToBambooVariables = { storageId: string; paragraphs: unknown[] }
export type UploadToBambooMutationOptions = MutateOptions<void, unknown, UploadToBambooVariables, undefined>
export type UploadToBambooFunctionType = (
  employeeId: string,
  categoryId: string,
  share?: boolean,
  splitId?: string | null,
  options?: UploadToBambooMutationOptions
) => void

const UploadToBambooMutationFunction = (variables: UploadToBambooVariables) => integrationsApi.uploadToBamboo(variables)

export const useUploadToBamboo = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()

  const uploadToBambooMutation = useMutation<void, unknown, UploadToBambooVariables, undefined>(
    [QUERY_KEYS.UPLOAD_TO_BAMBOO, id],
    UploadToBambooMutationFunction
  )

  const uploadToBambooMutationFunction: UploadToBambooFunctionType = useCallback(
    (employeeId, categoryId, share = false, splitId = null, options) => {
      const document = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, document?.templateId]) as ITemplate
      if (!(document && template)) return
      const { name, answers, languages, integrations } = document
      const [paragraphs, headers, footers] = parseAnswersForDownload(template!, languages, integrations, splitId, answers)
      const payload = { storageId: template.dataStructure.storageId, name, paragraphs, categoryId, employeeId, isShared: share }
      if (headers?.length) Object.assign(payload, { headers })
      if (footers?.length) Object.assign(payload, { footers })
      uploadToBambooMutation.mutate(payload, options)
    },
    [queryClient, uploadToBambooMutation, id]
  )

  return {
    upload: uploadToBambooMutationFunction,
    uploading: uploadToBambooMutation.isLoading,
  }
}

export default useUploadToBamboo
