import React, { forwardRef, useState, useMemo, useImperativeHandle, useCallback } from 'react'

import { SwitchProps, classes } from '.'
import Button from '../Button'

export const Switch = React.memo(
  forwardRef<{ value: boolean | undefined }, SwitchProps>((props, ref) => {
    const { className: cn, value, defaultValue, disabled, tabbable = true, title, onClickBlur, onClick, onFocus, pin, children } = props

    const [state, setState] = useState(Boolean(defaultValue))

    const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])
    const computedState = useMemo(() => (value === undefined ? state : value), [value, state])

    const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent', 'red', 'green', 'blue', 'orange', 'yellow'].find(
      prop => props[prop as keyof SwitchProps]
    )
    const baselineDataSet = useMemo(() => (computedState ? { emphasis } : {}), [emphasis, computedState])
    const dataSet = useMemo(
      () => Object.assign({}, baselineDataSet, props.dataSet, { toggle: ['off', 'on'][Number(computedState)] }),
      [baselineDataSet, props.dataSet, computedState]
    )

    const onClickHandler = useCallback(() => {
      if (value === undefined) setState(prev => !prev)
      else if (typeof onClick === 'function') onClick(!value)
    }, [value, onClick])

    useImperativeHandle(ref, () => ({ value: state }))

    return (
      <Button
        className={className}
        onClick={onClickHandler}
        dataSet={dataSet}
        disabled={disabled}
        tabbable={tabbable}
        title={title}
        onClickBlur={onClickBlur}
        onFocus={onFocus}
      >
        <div className={classes.pin}>{pin}</div>
        {children}
      </Button>
    )
  })
)

Switch.displayName = 'Casus-Components-Switch'

export default Switch
