import { BambooEmployeeCategory } from '___types'

import API from './api'

class Documents extends API {
  public constructor() {
    super('')
  }

  // =================================================================== //
  // ============================ BAMBOO-HR ============================ //
  // =================================================================== //
  public getBambooEmployeeCategories = async (id?: string | null) => {
    if (!id) return
    const res = await this.get(`/users/external/services/bamboo-hr/employee/${id}/categories`, undefined, 'v1')
    return res?.data?.data?.categories?.categories as BambooEmployeeCategory[]
  }

  public uploadToBamboo = async (payload: { storageId: string; paragraphs: unknown[] }) => {
    await this.post('users/external/services/employee/upload', payload, { responseType: 'blob' }, 'v1')
  }
  // =================================================================== //
}

export const documentsAPI = new Documents()

export default documentsAPI
