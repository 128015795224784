import TemplatesApi from 'api/templates.api'
import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import { useUser } from 'queries/user/useUser'

export const useFetchTemplate = (id?: string | null | false, isPublic?: boolean, documentId?: string) => {
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const query = useQuery([queryKeys.TEMPLATE, id], () => (id ? TemplatesApi.getOne(id, isPublic, docXMicro, documentId) : null), {
    enabled: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
  return query
}
