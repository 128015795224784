import { useCallback, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { Author, DOCUMENT_STATUSES, IDocument, ITemplate, IUser, PartialDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'
import { generateDocumentProgress } from '.'

const generateDocument = (author: Author, document: PartialDocument, parent?: string | null) => {
  const moment = { _seconds: Math.floor(Date.now() / 1000), _nanoseconds: 0 }
  return Object.assign(
    {
      status: DOCUMENT_STATUSES.DRAFT,
      authorId: author?.id,
      author: { id: author?.id, email: author?.email, firstName: author?.firstName, lastName: author?.lastName, imageUrl: author?.imageUrl },
      parentCategoryId: parent,
      created: moment,
      edited: moment,
      sharingEnabled: false,
      sharedWith: [],
    },
    document
  )
}

export type CreateDocumentVariables = { document: PartialDocument; publicFlow?: boolean }
export type CreateDocumentContext = { mutationId: string }
export type CreateDocumentMutationOptions = MutationOptions<IDocument, unknown, CreateDocumentVariables, CreateDocumentContext>
export type CreateDocumentFunctionType = (document: PartialDocument, options?: CreateDocumentMutationOptions) => void
const createDocumentMutationFunction = (variables: CreateDocumentVariables) => documentsAPI.createDocument(variables.document, variables.publicFlow)

export const useCreateDocument = (publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: IDocument[] | undefined) => IDocument[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: CreateDocumentVariables) => {
    if (!publicFlow) {
      const author = queryClient.getQueryData([QUERY_KEYS.USER]) as IUser
      const optimisticPayload = { id: mutationId, mutating: true, mutation: 'create' }
      const optimisticDocument = Object.assign(generateDocument(author, variables.document), optimisticPayload) as IDocument
      updateListing(data => [optimisticDocument].concat(data || []))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: CreateDocumentVariables, context: CreateDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    if (publicFlow) return
    updateListing((data: IDocument[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (document: IDocument, variables: CreateDocumentVariables, context: CreateDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT, document.id], document)
    if (!publicFlow) updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, document))
  }

  const onSettled = () => {
    queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
    queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
  }

  const documentCreateMutation = useMutation<IDocument, unknown, CreateDocumentVariables, CreateDocumentContext>(
    [QUERY_KEYS.DOCUMENT, mutationId].concat(publicFlow ? 'public' : []),
    createDocumentMutationFunction,
    { onMutate, onError, onSuccess, onSettled }
  )

  const createMutationFunction: CreateDocumentFunctionType = useCallback(
    (document, options) => {
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, document.templateId]) as ITemplate
      if (template) Object.assign(document, { progress: generateDocumentProgress(template.questionLayout, template.questions, document.answers) })
      return documentCreateMutation.mutate({ document, publicFlow }, options)
    },
    [queryClient, documentCreateMutation, publicFlow]
  )

  return { create: createMutationFunction, creating: documentCreateMutation.isLoading }
}

export default useCreateDocument
