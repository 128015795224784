import { PropsWithChildren, FocusEventHandler, ReactNode } from 'react'

import Switch from './Switch'

import './style.scss'

const classes = { wrapper: 'Casus-Components-Switch-wrapper', pin: 'Casus-Components-Switch-pin' } as const

export type SwitchProps = PropsWithChildren<{
  className?: string
  value?: boolean
  defaultValue?: boolean
  emphasized?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  red?: boolean
  green?: boolean
  blue?: boolean
  orange?: boolean
  yellow?: boolean
  disabled?: boolean
  dataSet?: Record<keyof any, unknown>
  tabbable?: boolean
  title?: string
  onClickBlur?: boolean
  onClick?: (state: boolean) => void
  onFocus?: FocusEventHandler<HTMLButtonElement>
  pin?: ReactNode
}>

export { Switch, classes }
export default Switch
