import moment from 'moment'
import { v4 as uuid } from 'uuid'

import {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  NESTED_STRUCTURE_KEYS,
  KEYSTRINGS,
  CASUS_IDS,
  measureStructureSegments,
  parseQuestionLocations,
  REPLACEMENT_TYPES,
} from './parsing'

const classes = {
  depthContainer: '_casus_depth_container',
  counterResetter: '_casus_counter_resetter',
  paragraphSegment: '_casus_paragraph_segment',
  tableSegment: '_casus_table_segment',
  tableHeader: '_casus_table_header',
  tableBody: '_casus_table_body',
  tableFooter: '_casus_table_footer',
  tableRow: '_casus_table_row',
  tableCell: '_casus_table_cell',
  textChunk: '_casus_text_chunk_span',
}

const applyValues = structure => {
  if (structure.value)
    return [
      Object.entries(structure)
        .filter(([key]) => key !== 'value')
        .reduce((acc, [key, value]) => {
          key === 'text' ? (acc[key] = structure.value) : (acc[key] = value)
          return acc
        }, {}),
      structure.value === KEYSTRINGS.remove,
    ]
  const result = { ...structure }
  let remove = true
  const foundKey = NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (result[key] && result[key].length) {
      const replacedSegments = result[key].reduce((accumulated, cur) => {
        const [replacedSegment, innerRemove] = applyValues(cur)
        if (!innerRemove) accumulated.push(replacedSegment)
        remove = innerRemove && remove
        return accumulated
      }, [])
      result[key] = replacedSegments
      return true
    }
    return acc
  }, false)
  return [result, foundKey ? remove : false]
}

const generateCounterResetter = (resetString = '') => {
  const countRe = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.paragraph])
  countRe.classList.add(classes.counterResetter)
  countRe.style.counterReset = resetString
  return countRe
}

const generateTableCellSegment = (segment, numberingSystem, questions = [], answers = []) => {
  const { customStyle = '', styles = [], content = [] } = segment
  const classesArray = [classes.tableData, customStyle, ...styles].filter(s => s)
  const cell = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.tableData])
  cell.classList.add(...classesArray)
  populateContainer(cell, { segments: content, numberingSystem }, questions, answers)
  return [cell, false]
}

const generateTableRowSegment = (segment, numberingSystem, questions = [], answers = []) => {
  const { customStyle = '', styles = [], cells = [] } = segment
  const classesArray = [classes.tableRow, customStyle, ...styles].filter(s => s)
  const row = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.tableRow])
  row.classList.add(...classesArray)
  let remove = !!cells.length
  cells.forEach(cell => {
    if (cell.id === KEYSTRINGS.highlight) {
      cell.content.forEach(innerCell => {
        const highlightedCell = innerCell.styles
          ? { ...innerCell, styles: [...innerCell.styles, KEYSTRINGS.highlight] }
          : { ...innerCell, styles: [KEYSTRINGS.highlight] }
        const [segment, innerRemove] = generateSegment(highlightedCell, numberingSystem, questions, answers)
        remove = innerRemove && remove
        if (!innerRemove) row.appendChild(segment)
      })
      // const [mark, innerRemove] = generateMarkSegment(cell, numberingSystem, questions, answers)
      // remove = innerRemove && remove
      // row.appendChild(mark)
      // if (!innerRemove) row.appendChild(mark)
    } else {
      const [segment, innerRemove] = generateSegment(cell, numberingSystem, questions, answers)
      remove = innerRemove && remove
      if (!innerRemove) row.appendChild(segment)
    }
  })
  return [row, remove]
}

const unpackMark = (mark, parent, numberingSystem, questions, answers) => {
  let remove = true
  mark.content.forEach(content => {
    if (content.id === KEYSTRINGS.highlight) remove = unpackMark(content, parent, remove) && remove
    else {
      const highlightedContent = { ...(content ?? []), styles: [...(content.styles ?? []), KEYSTRINGS.highlight] }
      const [segment, innerRemove] = generateSegment(highlightedContent, numberingSystem, questions, answers)
      remove = innerRemove && remove
      if (!innerRemove) parent.appendChild(segment)
    }
  })
  return remove
}

const generateTableSectionSegment = (segment, numberingSystem, questions = [], answers = []) => {
  const { tag = 'tbody', rows = [] } = segment
  const section = document.createElement(tag)
  const type =
    (Object.entries(SEGMENT_TYPES).find(([_, value]) => value === (Object.entries(SEGMENT_TAGS).find(([_, value]) => value === tag) || [])[0]) ||
      [])[0] || 'tableBody'
  section.classList.add(classes[type])
  let remove = !!rows.length
  rows.forEach(row => {
    if (row.id === KEYSTRINGS.highlight) {
      remove = unpackMark(row, section) && remove
      // const innerSegment = { ...row, tag }
      // const [mark, innerRemove] = generateMarkSegment(innerSegment, numberingSystem, questions, answers)
      // remove = innerRemove && remove
      // if (!innerRemove) section.appendChild(mark)
    } else {
      const [segment, innerRemove] = generateSegment(row, numberingSystem, questions, answers)
      remove = innerRemove && remove
      if (!innerRemove) section.appendChild(segment)
    }
  })
  return [section, remove]
}

const generateTableSegment = (segment, numberingSystem, questions = [], answers = []) => {
  const { id = '', customStyle = '', styles = [], tableHeader = [], tableBody = [], tableFooter = [] } = segment
  const classesArray = [classes.tableSegment, customStyle, ...styles].filter(s => s)
  const table = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.table])
  table.id = id
  table.classList.add(...classesArray)
  let remove = !!tableHeader.length || !!tableBody.length || !!tableFooter.length
  if (tableHeader.length) {
    const [tableSection, innerRemove] = generateSegment(
      { tag: SEGMENT_TAGS[SEGMENT_TYPES.tableHeader], rows: tableHeader },
      numberingSystem,
      questions,
      answers
    )
    remove = innerRemove && remove
    if (!innerRemove) table.appendChild(tableSection)
  }
  if (tableBody.length) {
    const [tableSection, innerRemove] = generateSegment(
      { tag: SEGMENT_TAGS[SEGMENT_TYPES.tableBody], rows: tableBody },
      numberingSystem,
      questions,
      answers
    )
    remove = innerRemove && remove
    if (!innerRemove) table.appendChild(tableSection)
  }
  if (tableFooter.length) {
    const [tableSection, innerRemove] = generateSegment(
      { tag: SEGMENT_TAGS[SEGMENT_TYPES.tableFooter], rows: tableFooter },
      numberingSystem,
      questions,
      answers
    )
    remove = innerRemove && remove
    if (!innerRemove) table.appendChild(tableSection)
  }
  return [table, remove]
}

const generateTextChunk = (segment = {}) => {
  const { text = '', customStyle = '', styles = [], value, drawing = null } = segment
  // if (!styles.length) return text
  const classesArray = [classes.textChunk, customStyle, drawing, ...styles].filter(s => s)
  const span = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.span])
  span.classList.add(...classesArray)
  if (value !== undefined) span.innerHTML = value
  else span.innerHTML = text
  return [span, value === KEYSTRINGS.remove]
}

const generateParagraphSegment = (segment = {}) => {
  const { id = '', customStyle = '', textChunks = [], styles = [] } = segment
  // const contentLength = textChunks.reduce((acc, { text }) => acc + text.length, 0)
  const classesArray = [classes.paragraphSegment, customStyle, ...styles].filter(s => s)
  // if (contentLength === 0) classesArray.push('empty')
  const paragraph = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.paragraph])
  paragraph.id = id
  paragraph.classList.add(...classesArray)
  let remove = !!textChunks.length
  textChunks.forEach(chunk => {
    const { id, text, customStyle = '', styles = [], value } = chunk
    const combinedStyles = [customStyle, ...styles].filter(s => s)
    if (id === KEYSTRINGS.highlight) {
      const [mark, innerRemove] = generateMarkSegment(chunk)
      remove = innerRemove && remove
      if (!innerRemove) paragraph.appendChild(mark)
    } else if (combinedStyles?.length) {
      const [segment, innerRemove] = generateSegment({ tag: 'textChunk', text, customStyle, styles, value })
      remove = innerRemove && remove
      if (!innerRemove) paragraph.appendChild(segment)
    } else {
      const chunkText = value !== undefined ? value : text
      remove = value === KEYSTRINGS.remove && remove
      if (chunkText !== KEYSTRINGS.remove) paragraph.innerHTML += chunkText
    }
  })
  return [paragraph, remove]
}

const generateMarkSegment = (segment, numberingSystem, questions = [], answers = []) => {
  const { tag = SEGMENT_TAGS[SEGMENT_TYPES.mark], qid = '', lid = '', sid = '', content } = segment
  const mark = document.createElement(tag)
  mark.classList.add(KEYSTRINGS.highlight)
  mark.dataset.qid = qid
  mark.dataset.lid = lid
  mark.dataset.sid = sid
  let remove = !!content.length
  content.forEach(c => {
    const { id, text, customStyle = '', styles = [], value } = c
    if (id === KEYSTRINGS.highlight) {
      const innerSegment = { ...c, tag }
      const [innerMark, innerRemove] = generateMarkSegment(innerSegment)
      remove = innerRemove && remove
      if (!innerRemove) mark.appendChild(innerMark)
    } else if (text?.length) {
      const combinedStyles = [customStyle, ...styles].filter(s => s)
      if (combinedStyles?.length) {
        const [segment, innerRemove] = generateSegment({ tag: 'textChunk', text, customStyle, styles, value })
        remove = innerRemove && remove
        if (!innerRemove) mark.appendChild(segment)
      } else {
        const chunkText = value !== undefined ? value : text
        remove = value === KEYSTRINGS.remove && remove
        if (chunkText !== KEYSTRINGS.remove) mark.innerHTML += chunkText
      }
    } else {
      const [segment, innerRemove] = generateSegment(c, numberingSystem, questions, answers)
      remove = innerRemove && remove
      if (!innerRemove) mark.appendChild(segment)
    }
  })
  return [mark, remove]
}

const generateSegment = (propSegment = {}, numberingSystem = {}, questions = [], answers = []) => {
  const segment = { ...propSegment }
  switch (segment.tag) {
    // case SEGMENT_TAGS[SEGMENT_TYPES.mark]:
    //   return generateMarkSegment(segment, numberingSystem, questions, answers)
    case SEGMENT_TAGS[SEGMENT_TYPES.paragraph]:
      return generateParagraphSegment(segment)
    case 'textChunk':
      return generateTextChunk(segment)
    case SEGMENT_TAGS[SEGMENT_TYPES.table]:
      return generateTableSegment(segment, numberingSystem, questions, answers)
    case SEGMENT_TAGS[SEGMENT_TYPES.tableHeader]:
    case SEGMENT_TAGS[SEGMENT_TYPES.tableBody]:
    case SEGMENT_TAGS[SEGMENT_TYPES.tableFooter]:
      return generateTableSectionSegment(segment, numberingSystem, questions, answers)
    case SEGMENT_TAGS[SEGMENT_TYPES.tableRow]:
      return generateTableRowSegment(segment, numberingSystem, questions, answers)
    case SEGMENT_TAGS[SEGMENT_TYPES.tableData]:
      return generateTableCellSegment(segment, numberingSystem, questions, answers)
    default:
      return [null, true]
  }
}

const getPlaceholderSegment = (type, value) => {
  switch (type) {
    case SEGMENT_TYPES.mark:
      return {
        id: value,
        type: SEGMENT_TYPES.mark,
        tag: SEGMENT_TAGS[SEGMENT_TYPES.mark],
        content: [],
        start: +Infinity,
      }
    case 'textChunk':
      const chunk = {
        text: '',
        styles: [],
        start: +Infinity,
      }
      if (value) chunk.value = value
      return chunk
    case REPLACEMENT_TYPES.text:
    case REPLACEMENT_TYPES.date:
    case REPLACEMENT_TYPES.number:
    case REPLACEMENT_TYPES.radio:
    default:
      return {
        id: uuid(),
        type: SEGMENT_TYPES.paragraph,
        tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
        textChunks: [{ value }],
        start: +Infinity,
      }
  }
}

const highlightSegments = (segments, change, key, sid, propagatedArray) => {
  let array = propagatedArray
  let done = false
  const res = segments
    .reduce((acc, cur) => {
      if (done) acc.push(cur)
      else {
        const { start = 0, length = 0 } = cur
        const end = start + length
        const { start: changeStart = 0, length: changeLength = 0 } = change
        const changeEnd = changeStart + changeLength
        if (end <= changeStart) acc.push(cur)
        else if (start >= changeEnd) {
          done = true
          acc.push(cur)
        } else {
          const segmentWithin = start >= changeStart && end <= changeEnd
          if (segmentWithin) {
            if (!!array) array.push(cur)
            else {
              const result = { ...cur }
              if (key === 'textChunks') result.tag = 'textChunk'
              array = [result]
              acc.push(array)
            }
          } else {
            const result = applyHighlight(cur, change, sid, array)
            const propArray = result.pop()
            if (!array && propArray) array = propArray
            acc.push(...result)
          }
        }
      }
      return acc
    }, [])
    .map(segment => {
      if (Array.isArray(segment))
        return segment.reduce(
          (acc, cur) => ({
            ...acc,
            content: [...acc.content, cur],
            start: Math.min(acc.start, cur.start),
            length: (acc.length || 0) + cur.length,
          }),
          { ...getPlaceholderSegment(SEGMENT_TYPES.mark, KEYSTRINGS.highlight), qid: change.sid, lid: change.lid, sid }
        )
      return segment
    })
  return [res, array]
}

const highlightTextChunks = (structure, change, propagatedArray) => {
  let array = propagatedArray
  const res = []
  const { start = 0, length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength
  const calculatedStart = Math.max(changeStart - start, 0)
  const calculatedEnd = Math.max(Math.min(changeEnd - start, structure.text.length), 0)
  const calculatedLength = calculatedEnd - calculatedStart
  const leading = {
    ...structure,
    length: calculatedStart,
    text: structure.text.slice(0, calculatedStart),
  }
  const base = {
    ...structure,
    tag: 'textChunk',
    start: Math.max(changeStart, start),
    length: calculatedLength,
    text: structure.text.slice(calculatedStart, calculatedEnd),
  }
  const trailing = {
    ...structure,
    start: Math.min(changeEnd, end),
    length: structure.text.length - calculatedEnd,
    text: structure.text.slice(calculatedEnd),
  }
  if (leading.length && leading.length > 0) res.push(leading)
  if (base.length && base.length > 0) {
    if (array) array.push(base)
    else {
      array = [base]
      res.push(array)
    }
  }
  if (trailing.length && trailing.length > 0) res.push(trailing)
  return [...res, array]
}

const applyHighlight = (structure = {}, change = {}, sid, propagatedArray) => {
  // console.log('APPLY HIGHLIGHT: ', structure, change, propagatedArray)
  const start = structure.id && structure.id !== KEYSTRINGS.highlight ? 0 : structure.start
  const { length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength

  if (end <= changeStart || start >= changeEnd) return [structure, propagatedArray]
  if (structure.text && structure.text.length) return highlightTextChunks(structure, change, propagatedArray)

  const result = { ...structure }
  let done = false
  NESTED_STRUCTURE_KEYS.every(key => {
    if (done) return false
    if (result[key] && result[key].length) {
      const [highlightedSegments, propArray] = highlightSegments(result[key], change, key, sid, propagatedArray)
      result[key] = highlightedSegments
      done = done || !!propArray
    }
    return true
  })
  return [result, propagatedArray]
}

const replaceSegments = (segments, change, key) => {
  let done = false
  let changed = false
  const res = segments
    .reduce((acc, cur) => {
      if (done) acc.push(cur)
      else {
        const { start = 0, length = 0 } = cur
        const end = start + length
        const { start: changeStart = 0, length: changeLength = 0 } = change
        const changeEnd = changeStart + changeLength
        if (end <= changeStart) acc.push(cur)
        else if (start >= changeEnd) {
          done = true
          acc.push(cur)
        } else {
          const segmentWithin = start >= changeStart && end <= changeEnd
          if (segmentWithin) {
            const accLength = acc.length
            const lastSegment = accLength && acc[accLength - 1]
            const isNesting = !!(lastSegment && Array.isArray(lastSegment))
            const result = { ...cur }
            if (key === 'textChunks') {
              result.value = changed ? KEYSTRINGS.remove : change.value
            }
            changed = true
            if (isNesting) lastSegment.push(result)
            else acc.push([result])
          } else {
            const result = applyReplacement(cur, change, changed ? KEYSTRINGS.remove : '')
            const innerChanged = result.pop()
            changed = changed || innerChanged
            acc.push(...result)
          }
        }
      }
      return acc
    }, [])
    .map(segment => {
      if (Array.isArray(segment)) {
        if (key === 'textChunks') {
          const styles = segment[0].styles || []
          const value = segment[0].value
          return segment.reduce(
            (acc, cur) => ({
              ...acc,
              text: acc.text + cur.text,
              start: Math.min(acc.start, cur.start),
              length: (acc.length || 0) + cur.length,
            }),
            { ...getPlaceholderSegment('textChunk', value), styles }
          )
        }
        return segment.reduce(
          (acc, cur) => ({ ...acc, start: Math.min(acc.start, cur.start), length: (acc.length || 0) + cur.length }),
          getPlaceholderSegment(change.questionType, change.value)
        )
      }
      return segment
    })
  return [res, changed]
}

const replaceTextChunks = (structure, change, propagatedValue) => {
  const res = []
  const { start = 0, length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength
  const calculatedStart = Math.max(changeStart - start, 0)
  const calculatedEnd = Math.max(Math.min(changeEnd - start, structure.text.length), 0)
  const calculatedLength = calculatedEnd - calculatedStart
  const leading = {
    ...structure,
    length: calculatedStart,
    text: structure.text.slice(0, calculatedStart),
  }
  const base = {
    ...structure,
    start: Math.max(changeStart, start),
    length: calculatedLength,
    text: structure.text.slice(calculatedStart, calculatedEnd),
    value: propagatedValue || change.value,
  }
  const trailing = {
    ...structure,
    start: Math.min(changeEnd, end),
    length: structure.text.length - calculatedEnd,
    text: structure.text.slice(calculatedEnd),
  }
  if (leading.length && leading.length > 0) res.push(leading)
  if (base.length && base.length > 0) res.push(base)
  if (trailing.length && trailing.length > 0) res.push(trailing)
  return res
}

const applyReplacement = (structure = {}, change = {}, propagatedValue = '') => {
  // console.log('APPLY REPLACEMENT: ', structure, change, propagatedValue)
  const start = structure.id && structure.id !== KEYSTRINGS.highlight ? 0 : structure.start
  const { length = 0 } = structure
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength

  if (end <= changeStart || start >= changeEnd) return [structure, false]
  if (structure.text && structure.text.length) return [...replaceTextChunks(structure, change, propagatedValue), true]

  const result = { ...structure }
  let done = false
  NESTED_STRUCTURE_KEYS.every(key => {
    if (done) return false
    if (result[key] && result[key].length) {
      const [replacedSegments, changed] = replaceSegments(result[key], change, key)
      result[key] = replacedSegments
      done = done || changed
    }
    return true
  })
  return [result, true]
}

const generateChildren = (structure = {}, replace = [], highlight = []) => {
  const relativeReplaceMarkers = replace[structure.id] || []
  const relativeHighlightMarkers = highlight[structure.id] || []

  const [replaced] = relativeReplaceMarkers.reduce((acc, cur) => applyReplacement(acc[0], cur), [structure])
  const [highlighted] = relativeHighlightMarkers.reduce((acc, cur) => applyHighlight(acc[0], cur, structure.id), [replaced])

  return NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (highlighted[key] && highlighted[key].length) acc[key] = (highlighted[key] || []).map(object => generateChildren(object, replace, highlight))
    return acc
  }, JSON.parse(JSON.stringify(structure)))
}

const findSegmentById = (segments = [], id = '') => {
  if (id === CASUS_IDS.rootElement) return { length: segments.reduce((acc, cur) => acc + cur.length, 0) }
  let result = null
  segments.every(segment => {
    if (result) return false
    if (segment.id === id) {
      result = segment
      return false
    }
    let inner = null
    NESTED_STRUCTURE_KEYS.every(key => {
      if (inner) return false
      if (segment[key] && segment[key].length) {
        inner = findSegmentById(segment[key], id)
        return !inner
      }
      return true
    })
    result = inner
    return !inner
  })
  return result
}

const splitMarkers = (markers = {}) =>
  Object.entries(markers).reduce(
    (acc, [id, markerArray]) => {
      markerArray.forEach(marker => {
        const index = Number(marker.value !== KEYSTRINGS.highlight)
        if (!acc[index][id]) acc[index][id] = []
        acc[index][id].push(marker)
      })
      return acc
    },
    [{}, {}]
  )

const filterMarkers = (markers = {}, segments) =>
  Object.entries(markers).reduce((acc, [id, markers]) => {
    const segment = findSegmentById(segments, id)
    if (segment) {
      const { toRemove, toReplace, toHighlight } = markers.reduce(
        (accumulated, marker) => {
          if (!marker.length) return accumulated
          if (marker.value === KEYSTRINGS.highlight) accumulated.toHighlight.push(marker)
          else if (marker.value === KEYSTRINGS.remove) accumulated.toRemove.push(marker)
          else accumulated.toReplace.push(marker)
          return accumulated
        },
        { toRemove: [], toReplace: [], toHighlight: [] }
      )
      const fullSegmentRemovalMarker = toRemove.find(marker => marker.start === 0 && marker.length === segment.length)
      if (fullSegmentRemovalMarker) return { ...acc, [id]: [fullSegmentRemovalMarker] }
      else {
        const toRemoveFiltered = toRemove
          .sort((a, b) => b.length - a.length)
          .reduce((accumulated, current) => {
            const inside = accumulated.some(marker => marker.start <= current.start && marker.end >= current.end)
            if (!inside) accumulated.push(current)
            return accumulated
          }, [])
        const toReplaceFiltered = toReplace.filter(
          marker => (marker.value !== KEYSTRINGS.keep) & !toRemoveFiltered.some(s => s.start <= marker.start && s.end >= marker.end)
        )
        const toHighlightFiltered = toHighlight.filter(marker => !toRemoveFiltered.some(s => s.start <= marker.start && s.end >= marker.end))
        const resultingMarkers = [...toRemoveFiltered, ...toReplaceFiltered, ...toHighlightFiltered]
        const result = { ...acc }
        if (resultingMarkers.length) result[id] = resultingMarkers
        return result
      }
    }
    return acc
  }, {})

const generateMarkers = (questions = [], answers = []) =>
  questions.reduce((acc, cur, i) => {
    const relativeAnswer = answers.find(({ questionId }) => questionId === cur.id)
    const locations = parseQuestionLocations(cur)
    const locationsWithValue = locations.map(({ id, start, length, qid, lid, optionId }) => {
      let value = KEYSTRINGS.highlight
      if (relativeAnswer) {
        if (optionId) value = relativeAnswer.value.includes(optionId) ? KEYSTRINGS.keep : KEYSTRINGS.remove
        else
          value =
            cur.type === REPLACEMENT_TYPES.date
              ? moment(relativeAnswer.value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD.MM.YYYY')
              : cur.type === REPLACEMENT_TYPES.radio
              ? cur.options.find(e => e.id === relativeAnswer.value[0])?.text
              : relativeAnswer.value
      }
      return { id, start, length, value, questionType: cur.type, qid, lid }
    })
    const result = locationsWithValue.reduce((accumulated, { id, start, length, value, questionType, qid, lid }) => {
      if (accumulated[id]) accumulated[id].push({ start, length, value, questionType, qid, lid, sid: id })
      else accumulated[id] = [{ start, length, value, questionType, qid, lid, sid: id }]
      return accumulated
    }, acc)
    return result
  }, {})

const extractSubQuestions = (questionArray = []) =>
  questionArray.reduce((acc, cur) => {
    if (cur.options?.length)
      cur.options.forEach(option => {
        return acc.push(...extractSubQuestions(option?.subquestions))
      })
    acc.push(cur)
    return acc
  }, [])

const parseDataStructure = (structure = {}, nestedQuestions = [], answers = []) => {
  const questions = extractSubQuestions(nestedQuestions) // unwrap/extract all questions from all depths (nested subquestions)
  // console.log('ALL QUESTIONS: ', questions)
  const segments = measureStructureSegments(structure) // measure the lengths (of text content) of all segments
  const markers = generateMarkers(questions, answers)
  const filteredMarkers = filterMarkers(markers, segments) // filter markers based on position relative to eachother (remove markers nested inside segments "to be removed by answers," etc.)
  // console.log('MARKERS: ', filteredMarkers)
  const [forHighlighting, forReplacement] = splitMarkers(filteredMarkers)
  // const changes = generateChanges(segments, filteredMarkers)
  // const result = generateResult(structure, changes) // generate resulting dataStructure with answered questions
  const lastSegment = segments.length ? segments[segments.length - 1] : null
  const length = lastSegment ? lastSegment.start + lastSegment.length : 0
  const result = { ...structure, segments, id: CASUS_IDS.rootElement, start: 0, length }
  return [result, forReplacement, forHighlighting]
}

const populateContainer = (container, dataStructure = {}, questions = [], answers = []) => {
  // console.log('DATA STRUCTURE: ', dataStructure)
  const [structure, forReplacement, forHighlighting] = parseDataStructure(dataStructure, questions, answers)
  // console.log('POPULATE CONTAINER: ', structure)
  const parsedDataStructure = generateChildren(structure, forReplacement, forHighlighting)
  // console.log(`PARSED DATA STRUCTURE (${container.nodeName}): `, parsedDataStructure)
  const { numberingSystem } = parsedDataStructure
  const { segments } = parsedDataStructure

  // console.log('SEGMENTS: ', segments)

  const [nestedChildren] = segments.reduce(
    (acc, cur) => {
      // console.log('SEGMENT: ', cur)
      const array = acc[0]
      let currentDepthStructure = acc[1]
      const [child, innerRemove] =
        cur.id === KEYSTRINGS.highlight
          ? generateMarkSegment({ ...cur, tag: SEGMENT_TAGS[SEGMENT_TYPES.container] }, numberingSystem)
          : generateSegment(cur, numberingSystem)
      if (!innerRemove) {
        if (cur.customStyle) {
          const [currentSystemName, currentSystem] =
            Object.entries(numberingSystem).find(([_, value]) => value.map(({ styleName }) => styleName).indexOf(cur.customStyle) !== -1) || []
          if (currentSystem) {
            let currentDepth = currentDepthStructure[currentSystemName] || 0
            const segmentDepth = currentSystem.map(({ styleName }) => styleName).indexOf(cur.customStyle) + 1 || 1
            const stepDirection = Math.sign(segmentDepth - currentDepth)
            if (stepDirection)
              for (let i = currentDepth + stepDirection; Math.sign(i - segmentDepth) !== stepDirection; i += stepDirection) {
                currentDepth = i
                if (stepDirection === 1) array.push(generateCounterResetter(`${classes.depthContainer}_${i}_${currentSystemName} 0`))
              }
            currentDepthStructure[currentSystemName] = currentDepth
          }
        }
        array.push(child)
      }
      return acc
    },
    [[], {}]
  )
  // console.log('NESTED CHILDREN: ', nestedChildren)
  nestedChildren.forEach(c => container.appendChild(c))
  // console.log(forReplacement)
  const forReplacementStructure = generateChildren(structure, forReplacement)
  // console.log(`FOR REPLACEMENT DATA STRUCTURE (${container.nodeName}): `, forReplacementStructure)
  const [replacedDataStructure] = applyValues(forReplacementStructure)
  // console.log(`REPLACED DATA STRUCTURE (${container.nodeName}): `, replacedDataStructure)
  return replacedDataStructure
}

export const generateHtml = (tempDataStructure = {}, questions = [], answers = []) => {
  const dataStructure = JSON.parse(JSON.stringify(tempDataStructure))
  // console.log('::::::::::::::::::::::::::::::::::::::')
  // console.log(dataStructure, questions, answers)
  // console.log('::::::::::::::::::::::::::::::::::::::')
  const container = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.container]) // placeholder container to get innerHTML from
  const root = document.createElement(SEGMENT_TAGS[SEGMENT_TYPES.container]) // root container of the document HTML
  const replacedDataStructure = populateContainer(root, dataStructure, questions, answers) // function to populate the given root container
  // console.log('REPLACED DATA STRUCTURE: ', replacedDataStructure)
  root.id = CASUS_IDS.rootElement
  container.appendChild(root)
  // console.log('HTML: ', container.innerHTML.replaceAll('<pre', '<p').replaceAll('/pre>', '/p>'))
  return [container.innerHTML, replacedDataStructure]
}
