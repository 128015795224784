import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchExternalServiceEntries } from '___queries'
import { useDocument, useTemplate } from '___hooks'
import { UseTemplateHookUnknownIdReturnType } from '___hooks/useTemplate'
import { Button, Switch } from 'components/CasusComponents'
import { uploadModalClasses as classes, IntegrationConfig, UploadConfig, UploadModalContentProps } from '.'
import EmployeeCategorySelect from './Upload.ModalContent.EmployeeCategorySelect'

export const ModalContent = React.memo(
  forwardRef<UploadConfig, UploadModalContentProps>(({ documentId }, ref) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'integrations.bamboo-hr' })

    const { data } = useFetchExternalServiceEntries('bamboo-hr')
    const { data: document } = useDocument(documentId)
    const { data: template } = useTemplate(document?.templateId!) as UseTemplateHookUnknownIdReturnType

    const splits = useMemo(() => {
      const templateSplits = Object.assign({}, template?.splits)
      if (Object.keys(templateSplits).length) Object.assign(templateSplits, { _full_document: translate('full-document') })
      return templateSplits
    }, [template, translate])

    const entries = data?.entries || []
    const displayFields = data?.displayFields || []
    const integrations = document?.integrations || {}
    const integrationEntries = document?.integrationEntries || {}

    const bambooIntegrations = useMemo(() => {
      return Object.entries(integrations).reduce(
        (result, [key, { id, cue }]) => Object.assign(result, id === 'bamboo-hr' ? { [key]: cue } : null),
        {} as Record<string, string>
      )
    }, [integrations])

    const [config, setConfig] = useState<UploadConfig>(
      Object.keys(bambooIntegrations).reduce((result, integrationId) => {
        const employeeId = integrationEntries[integrationId]
        const integrationConfig = { active: false, employeeId, categoryId: undefined, share: false, splitId: null } as IntegrationConfig
        return Object.assign(result!, { [integrationId]: integrationConfig })
      }, {} as UploadConfig)
    )

    const employees = useMemo(
      () =>
        Object.entries(bambooIntegrations).reduce((result, [id, cue]) => {
          const employeeId = integrationEntries[id]
          if (!employeeId) return result
          const documentFieldValues = integrations[id]?.fields?.reduce((result, field) => Object.assign(result, { [field.id]: field.value }), {})
          const entry = entries.find(entry => entry.id === employeeId)
          const employee = Object.assign({ _casus_integration_id: id, _casus_cue: cue }, entry, documentFieldValues)
          return result.concat(employee)
        }, [] as { id: string; _casus_integration_id: string; _casus_cue: string; [K: string]: unknown }[]),
      [bambooIntegrations, integrationEntries, integrations, entries]
    )

    const updateConfig = useCallback(
      (id: string, payload: Record<string, unknown>) =>
        setConfig(prev => {
          const resultingIntegrationConfig = Object.assign({}, prev?.[id], payload)
          return Object.assign({}, prev, { [id]: resultingIntegrationConfig })
        }),
      []
    )

    useImperativeHandle(ref, () => config)

    return (
      <>
        {employees.map(employee => {
          const integrationId = employee._casus_integration_id as string
          const isActive = config?.[integrationId]?.active ?? false
          const isShared = config?.[integrationId]?.share ?? false
          const category = config?.[integrationId]?.categoryId
          const activeSplit = config?.[integrationId]?.splitId || '_full_document'
          return (
            <div
              key={`Integrations-Bamboo-Upload-ModalContent-integration-id-${integrationId}`}
              className={classes.employee}
              data-collapse={!isActive ? '' : undefined}
            >
              <span className={classes.integrationCue}>{employee._casus_cue}</span>
              <Switch className={classes.switch} value={isActive} onClick={value => updateConfig(integrationId, { active: value })} green />
              {isActive ? (
                <div id={integrationId} className={classes.details}>
                  <span className={classes.display}>{displayFields?.map(field => employee[field]).join(' ')}</span>
                  <div className={classes.splits}>
                    {Object.entries(splits).map(([id, label]) => (
                      <Button
                        key={`Integrations-Bamboo-Upload-ModalContent-integration-id-${integrationId}-split-id${id}`}
                        onClick={() => updateConfig(integrationId, { splitId: id === '_full_document' ? null : id })}
                        disabled={activeSplit === id}
                        tertiary={activeSplit === id}
                      >
                        <span>{label}</span>
                      </Button>
                    ))}
                  </div>
                  <EmployeeCategorySelect id={employee.id} value={category} onSelect={id => updateConfig(integrationId, { categoryId: id })} />
                  <Switch className={classes.switch} value={isShared} onClick={value => updateConfig(integrationId, { share: value })} blue>
                    <span>{translate('share')}</span>
                  </Switch>
                </div>
              ) : null}
            </div>
          )
        })}
      </>
    )
  })
)

ModalContent.displayName = 'Integrations-Bamboo-Upload-ModalContent'

export default ModalContent
