import React, { FunctionComponent, RefCallback, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStore, {
  getUnansweredQuestionInfo,
  UpdateWizardStateAction,
  UpdateWizardTitleAction,
  WizardAnswerRelevanceSelector,
  WizardAnswersSelector,
  WizardIntegrationsSelector,
  WizardIntegrationEntriesSelector,
  WizardLanguagesSelector,
  WizardModeSelector,
  WizardQuestionOrderSelector,
  WizardTemplateIdSelector,
} from '___store'

import { BREAK_TAG_MATCH, CASUS_INPUT_TYPES, DOCUMENT_DIRECTORY, DOCUMENT_FLOW_MODES, PartialDocument } from '___types'
import { useDocument, useTemplate } from '___hooks'
import { UseDocumentHookUnknownIdReturnType } from '___hooks/useDocument'
import { Caret } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { Confirm, Rename, SkippedQuestions } from './Review.Components'
import { wizardLayoutLeftPaneDocumentReviewClasses as classes, useWizardLayoutContext } from 'Layouts/WizardLayout'
import { useIsDocumentDirty } from 'Layouts/WizardLayout/hooks'

type UseStoreHookResultType = {
  wizardMode: WizardModeSelector
  wizardTemplateId: WizardTemplateIdSelector
  wizardQuestionOrder: WizardQuestionOrderSelector
  wizardLanguages: WizardLanguagesSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  wizardIntegrations: WizardIntegrationsSelector
  wizardAnswers: WizardAnswersSelector
  wizardAnswerRelevance: WizardAnswerRelevanceSelector
  updateWizardState: UpdateWizardStateAction
  updateWizardTitle: UpdateWizardTitleAction
}

export const Review: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.review' })
  const history = useHistory()
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }

  const [input, setInput] = useState<HTMLInputElement>()
  const callbackHolder = useRef((id: string) => {
    history.push(`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${id}/preview`)
    updateWizardState({ mode: DOCUMENT_FLOW_MODES.PREVIEW })
  })
  const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])

  const {
    wizardMode,
    wizardTemplateId,
    wizardQuestionOrder,
    wizardLanguages,
    wizardIntegrationEntries,
    wizardIntegrations,
    wizardAnswers,
    wizardAnswerRelevance,
    updateWizardState,
    updateWizardTitle,
  } = useStore(
    'selectWizardMode',
    'selectWizardTemplateId',
    'selectWizardQuestionOrder',
    'selectWizardLanguages',
    'selectWizardIntegrationEntries',
    'selectWizardIntegrations',
    'selectWizardAnswers',
    'selectWizardAnswerRelevance',
    'updateWizardState',
    'updateWizardTitle'
  ) as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const {
    create,
    update,
    data: { templateContentVersionId, name, languages, integrations, answers } = {},
    creating,
    updating,
  } = useDocument((documentId === 'new' ? undefined : documentId)!, publicFlow) as UseDocumentHookUnknownIdReturnType
  const mutationLoading = creating || updating
  const { data: template } = useTemplate(templateId!, publicFlow)

  const isDirty = useIsDocumentDirty(languages, integrations, answers)
  const defaultValue = documentId === 'new' ? `DRAFT: ${template?.name}` : name

  const [firstUnansweredQuestionOrderId, unansweredQuestionCount] = useMemo(
    () => getUnansweredQuestionInfo(wizardAnswers!, wizardQuestionOrder!),
    [wizardAnswers, wizardQuestionOrder]
  )
  const [incomplete, setIncomplete] = useState(true)

  const renameButtonText = useMemo(
    () => translate(wizardMode === DOCUMENT_FLOW_MODES.NEW ? 'save-and-preview' : 'rename-and-preview'),
    [translate, wizardMode]
  )

  const backButtonText = useMemo(() => {
    if (incomplete && unansweredQuestionCount) return translate('review')
    return translate('back')
  }, [incomplete, unansweredQuestionCount, translate])

  const continueButtonText = useMemo(() => {
    if (incomplete) return unansweredQuestionCount ? translate('continue') : translate('confirm')
    return translate(isDirty ? 'save-and-preview' : 'preview')
  }, [incomplete, unansweredQuestionCount, translate, isDirty])

  const onSuccess = useCallback(document => {
    if (typeof callbackHolder.current === 'function') callbackHolder.current(document.id)
  }, [])

  const saveUpdateCallback = useCallback(
    (renameInput: string) => {
      if (wizardMode === DOCUMENT_FLOW_MODES.NEW) {
        const payload = {
          templateId: wizardTemplateId,
          name: renameInput,
          languages: wizardLanguages?.selected || [],
          integrationEntries: wizardIntegrationEntries,
          integrations: wizardIntegrations,
          answers: wizardAnswers,
          answerRelevance: wizardAnswerRelevance,
        } as PartialDocument
        return create!(payload, { onSuccess })
      }
      if (wizardMode === DOCUMENT_FLOW_MODES.EDIT) {
        const payload = { id: documentId!, templateContentVersionId, name: renameInput } as PartialDocument
        if (isDirty)
          Object.assign(payload, {
            languages: wizardLanguages?.selected || [],
            integrationEntries: wizardIntegrationEntries,
            integrations: wizardIntegrations,
            answers: wizardAnswers,
            answerRelevance: wizardAnswerRelevance,
          })
        return update!(payload, { onSuccess })
      }
    },
    [
      wizardMode,
      wizardTemplateId,
      wizardLanguages,
      wizardIntegrationEntries,
      wizardIntegrations,
      wizardAnswers,
      wizardAnswerRelevance,
      create,
      onSuccess,
      documentId,
      templateContentVersionId,
      isDirty,
      update,
    ]
  )

  const confirmHandler = useCallback(() => {
    const textarea = document.createElement('textarea')
    //@ts-ignore
    textarea.innerHTML = input.value.replaceAll(BREAK_TAG_MATCH, '\n')
    const inputValue = textarea.value
    textarea.remove()
    updateWizardTitle(inputValue)
    saveUpdateCallback(inputValue)
  }, [input, updateWizardTitle, saveUpdateCallback])

  const backHandler = useCallback(() => {
    if (incomplete) {
      updateWizardState({ answering: unansweredQuestionCount ? firstUnansweredQuestionOrderId : wizardQuestionOrder?.[0] })
      return history.push(`/${publicFlow ? 'public' : DOCUMENT_DIRECTORY}/${templateId}/${documentId || 'new'}`)
    }
    return setIncomplete(true)
  }, [
    incomplete,
    unansweredQuestionCount,
    updateWizardState,
    firstUnansweredQuestionOrderId,
    wizardQuestionOrder,
    history,
    publicFlow,
    templateId,
    documentId,
  ])

  const continueHandler = useCallback(() => {
    if (publicFlow && typeof callbackHolder.current === 'function') return saveUpdateCallback(defaultValue!)
    if (incomplete) return setIncomplete(false)
    if (!isDirty && typeof callbackHolder.current === 'function') return callbackHolder.current(documentId!)
    const textarea = document.createElement('textarea')
    //@ts-ignore
    textarea.innerHTML = input.value.replaceAll(BREAK_TAG_MATCH, '\n')
    const inputValue = textarea.value
    textarea.remove()
    saveUpdateCallback(inputValue)
  }, [publicFlow, defaultValue, incomplete, isDirty, documentId, input, saveUpdateCallback])

  return (
    <div className={classes.wrapper}>
      {incomplete ? unansweredQuestionCount ? <SkippedQuestions questionCount={unansweredQuestionCount} /> : <Confirm /> : <Rename />}
      {!incomplete ? (
        <div className={classes.renameInput}>
          <Input
            ref={inputRef}
            type={CASUS_INPUT_TYPES.TEXT}
            tertiary
            defaultValue={defaultValue}
            placeholder={translate('title-placeholder')}
            autoselect
            showActionButtons={false}
            onConfirm={confirmHandler}
          />
          {wizardMode === DOCUMENT_FLOW_MODES.EDIT ? (
            <Button onClick={confirmHandler} disabled={mutationLoading} loading={mutationLoading} tertiary>
              {renameButtonText}
            </Button>
          ) : null}
        </div>
      ) : null}
      <div className={classes.actions}>
        <Button onClick={backHandler} tertiary={Boolean(incomplete && unansweredQuestionCount)}>
          <Caret />
          {backButtonText}
        </Button>
        <Button
          onClick={continueHandler}
          disabled={mutationLoading || Boolean(publicFlow && unansweredQuestionCount)}
          loading={mutationLoading}
          tertiary={incomplete ? !unansweredQuestionCount : wizardMode === DOCUMENT_FLOW_MODES.NEW}
        >
          {continueButtonText}
          <Caret />
        </Button>
      </div>
    </div>
  )
})

Review.displayName = 'WizardLayout-LeftPane-Document-Review'

export default Review
