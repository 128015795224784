import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Upload as UploadIcon } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import { uploadClasses as classes, UploadConfig, UploadProps } from '.'
import ModalContent from './Upload.ModalContent'
import { UploadToBambooFunctionType, UploadToBambooVariables, useUploadToBamboo } from '___queries'

type BatchUploadOnSuccessFunction = (res: void, variables: UploadToBambooVariables, context: undefined) => void

export const Upload: FunctionComponent<UploadProps> = React.memo(({ documentId, disabled }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'integrations.bamboo-hr' })
  const [uploadingDetails, setUploadingDetails] = useState<string[]>([])
  const config = useRef<UploadConfig>()

  const { upload, uploading } = useUploadToBamboo(documentId)

  const batchUpload = useCallback(
    close => {
      if (Object.values(config.current || {}).every(config => !config.active))
        return (document.querySelector(`div.${classes.modal.employee}:first-child>.${classes.modal.switch}>button`) as HTMLButtonElement)?.focus()

      const invalidPayload = Object.entries(config.current || {}).filter(([_, config]) => config.active && !config.categoryId)
      if (invalidPayload.length)
        return (document.getElementById(invalidPayload[0][0])?.querySelector(`div.${classes.modal.select}>span`) as HTMLSpanElement)?.focus()

      const [uploadPayloadArray, uploadDetails] = Object.entries(config.current || {}).reduce(
        (result, [integrationId, config]) => {
          if (config.active && config.categoryId) {
            result[0].push([config.employeeId, config.categoryId!, config.share || false, config.splitId || null])
            result[1].push(integrationId)
          }
          return result
        },
        [[], []] as [Parameters<UploadToBambooFunctionType>[], string[]]
      )

      uploadPayloadArray.reverse()
      uploadDetails.reverse()
      if (!uploadPayloadArray.length) return close()
      uploadPayloadArray[0].push({
        onSuccess: () => {
          setUploadingDetails(prev => prev.filter(integrationId => integrationId !== uploadDetails[0]))
        },
      })

      //@ts-ignore
      const uploadParameters = uploadPayloadArray.reduce(
        (result: Parameters<UploadToBambooFunctionType>, params: Parameters<UploadToBambooFunctionType>, i: number) =>
          params.concat({
            onSuccess: (() => {
              setUploadingDetails(prev => prev.filter(integrationId => integrationId !== uploadDetails[i]))
              upload(...result)
            }) as BatchUploadOnSuccessFunction,
          }) as Parameters<UploadToBambooFunctionType>
      ) as Parameters<UploadToBambooFunctionType>

      setUploadingDetails(uploadDetails)
      upload(...uploadParameters)
      close()
    },
    [upload]
  )

  const open = useModal({
    className: classes.modal.wrapper,
    //@ts-ignore
    header: translate('upload-to-bamboo'),
    content: <ModalContent ref={config} documentId={documentId} />,
    footer: close => (
      <Button onClick={() => batchUpload(close)} emphasized>
        {translate('upload')}
      </Button>
    ),
  })

  return (
    <Button
      className={classes.wrapper}
      onClick={() => open()}
      disabled={disabled || uploading || Boolean(uploadingDetails.length)}
      loading={uploading || Boolean(uploadingDetails.length)}
    >
      <UploadIcon />
      {translate(uploading ? 'uploading' : 'upload-to-bamboo')}
      {uploadingDetails.length ? ` [${uploadingDetails.length}]` : null}
    </Button>
  )
})

Upload.displayName = 'Integrations-Bamboo-Upload'

export default Upload
