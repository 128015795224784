import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchBambooEmployeeCategories } from '___queries'
import { Select } from 'components/CasusComponents'
import { uploadModalClasses as classes, UploadModalContentEmployeCategorySelectProps } from '.'

export const EmployeeCategorySelect: FunctionComponent<UploadModalContentEmployeCategorySelectProps> = React.memo(({ id, value, onSelect }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'integrations.bamboo-hr' })

  const { data } = useFetchBambooEmployeeCategories(id)

  const options = useMemo(() => (data || []).reduce((result, category) => Object.assign(result, { [category.id]: category.name }), {}), [data])

  return <Select className={classes.select} options={options} value={value} placeholder={translate('select-category')} onSelect={onSelect} />
})

EmployeeCategorySelect.displayName = 'Integrations-Bamboo-Upload-ModalContent-EmployeeCategorySelect'

export default EmployeeCategorySelect
