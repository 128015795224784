import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, { SelectedDocumentIdSelector, DeselectDocumentsAction } from '___store'

import { useDocument, useUser } from '___hooks'
import { Cross, DocumentPlaceholder } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { Actions, Info, Publishing, Relocation, Sharing } from './Document.components'
import { MainLayoutRightPaneDocumentProps, mainLayoutRightPaneDocumentClasses as classes } from '../../..'
import { DOCUMENT_STATUSES, DocumentStatus } from '___types'
import Signature from './Document.components/Signature'
import { Bamboo } from 'components/Integrations'

type UseStoreHookResultType = {
  selectedDocumentId: SelectedDocumentIdSelector
  deselectDocuments: DeselectDocumentsAction
}

export const Document: FunctionComponent<MainLayoutRightPaneDocumentProps> = React.memo(() => {
  const [renaming, setRenaming] = useState(false)
  const { selectedDocumentId, deselectDocuments } = useStore('selectSelectedDocumentId', 'deselectDocuments') as UseStoreHookResultType

  const { data: user } = useUser()
  const {
    data: {
      type,
      status,
      // progress,
      author,
      integrations,
      sharedWith,
    } = {},
    duplicate,
    share,
    unshare,
    update,
    remove,
    downloadRegular,
    downloadPDF,
    downloading,
    template: { data: { splits = {} } = {} },
  } = useDocument(selectedDocumentId!)

  const owned = user && author?.id === user.id

  const documentPreview = useMemo(() => {
    //   if (imageLoading) return <Spinner />
    //   return src ? <img src={src} alt="Document Preview" /> : <DocumentPlaceholder randomize />
    return <DocumentPlaceholder randomize />
  }, [])

  const renamingCallback = useCallback(() => {
    if (renaming) {
      const inputWrapper = document.getElementById(`MainLayout-RightPane-Document-${selectedDocumentId}-Input`)
      ;(inputWrapper?.firstChild as HTMLPreElement)?.focus()
    } else setRenaming(true)
  }, [renaming, selectedDocumentId])

  const renameCallback = useCallback(
    name => {
      if (name) update({ name })
      setRenaming(false)
    },
    [update]
  )

  const duplicateCallback = useCallback(() => {
    duplicate()
  }, [duplicate])

  const removeCallback = useCallback(() => {
    remove()
    deselectDocuments()
  }, [remove, deselectDocuments])

  const showSignatureSegment = useMemo(
    () => ([DOCUMENT_STATUSES.FINAL, DOCUMENT_STATUSES.PENDING_SIGNATURE, DOCUMENT_STATUSES.SIGNED] as DocumentStatus[]).includes(status!),
    [status]
  )

  const showBambooHRSegment = useMemo(
    () => status === DOCUMENT_STATUSES.FINAL && Object.values(integrations || {}).some(integration => integration.id === 'bamboo-hr'),
    [status, integrations]
  )

  useEffect(() => {
    setRenaming(false)
  }, [selectedDocumentId])

  return (
    <div className={classes.wrapper}>
      <Button onClick={deselectDocuments} onClickBlur transparent>
        <Cross />
      </Button>
      {selectedDocumentId ? (
        <>
          <div className={classes.preview}>{documentPreview}</div>
          <Actions
            id={selectedDocumentId}
            type={type!}
            downloadable={!Object.keys(splits).length}
            downloading={downloading}
            downloadRegularCallback={downloadRegular}
            downloadPDFCallback={downloadPDF}
            renameCallback={renamingCallback}
            duplicateCallback={duplicateCallback}
            removeCallback={removeCallback}
          />
          <Info id={selectedDocumentId} renaming={renaming} renameCallback={renameCallback} />
          {/* <Progress id={selectedDocumentId} status={status} progress={progress} publishing={publishing} publishingCallback={publishingCallback} /> */}
          {owned && type !== 'pdf' ? <Publishing id={selectedDocumentId} /> : null}
          {owned ? <Relocation id={selectedDocumentId} /> : null}
          {owned ? (
            <Sharing
              key={`MainLayout-RightPane-Document-${selectedDocumentId}-Sharing`}
              id={selectedDocumentId!}
              shareCallback={share}
              unshareCallback={unshare}
              sharedWith={sharedWith || []}
            />
          ) : null}
          {showSignatureSegment ? <Signature id={selectedDocumentId} /> : null}
          {showBambooHRSegment ? (
            <div className="MainLayout-RightPane-Document-BambooHR-wrapper">
              <span className="MainLayout-RightPane-Document-BambooHR-title">
                BambooHR
                <Bamboo.Upload documentId={selectedDocumentId} />
              </span>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
})

Document.displayName = 'MainLayout-RightPane-Document'

export default Document
