import React, { FunctionComponent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DOCUMENT_DIRECTORY, DOCUMENT_STATUSES, DocumentStatus } from '___types'
import { useDocument, useUser } from '___hooks'
import { Bin, Burger, Document, Docx, EditField, Eye, Pdf } from 'assets/svgIconComponents'
import { Button, Dropdown } from 'components/CasusComponents'
import { MainLayoutRightPaneDocumentActionsProps, mainLayoutRightPaneDocumentActionsClasses as classes } from '../../../..'

const editableStatuses = [DOCUMENT_STATUSES.DRAFT, DOCUMENT_STATUSES.FINAL, DOCUMENT_STATUSES.PENDING_APPROVAL] as DocumentStatus[]
// const approvalStatuses = [DOCUMENT_STATUSES.LOCKED, DOCUMENT_STATUSES.PENDING_APPROVAL] as DocumentStatus[]
const preparedStatuses = [DOCUMENT_STATUSES.LOCKED, DOCUMENT_STATUSES.FINAL, DOCUMENT_STATUSES.SIGNED] as DocumentStatus[]

export const Actions: FunctionComponent<MainLayoutRightPaneDocumentActionsProps> = React.memo(
  ({ id, type, downloadable, downloading, downloadRegularCallback, downloadPDFCallback, renameCallback, duplicateCallback, removeCallback }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })
    const history = useHistory()
    const { data: user } = useUser()
    const {
      data,
      isLoading,
      template, // remove after deprecating v2
    } = useDocument(id)

    const { status, author, sharedWith, templateId, version = 'v3' } = data || {}

    const owned = author?.id === user?.id
    const editable =
      type !== 'pdf' &&
      version === 'v3' &&
      editableStatuses.includes(status!) &&
      (owned || sharedWith?.find(({ id, permissions }) => id === user?.id && permissions.write))

    const actions = {} as Record<string, { callback: () => void; icon: JSX.Element; label: string }>
    if (editable) Object.assign(actions, { rename: { callback: renameCallback, icon: <EditField />, label: translate('rename') } })
    if (owned)
      Object.assign(actions, {
        duplicate: { callback: duplicateCallback, icon: <Document function="duplicate" />, label: translate('duplicate') },
        remove: { callback: removeCallback, icon: <Bin />, label: translate('remove') },
      })

    const dropdownActions = useMemo(
      () =>
        Object.entries(actions).map(([id, { callback, icon, label }]) => (
          <Button key={`MainLayout-Header-Navigation-Button-${id}`} onClick={callback} onClickBlur noBackgroundSVG noOverlaySVG>
            {label}
            {icon}
          </Button>
        )),
      [actions]
    )

    return (
      <div className={classes.wrapper}>
        {version === 'v3' ? (
          <Button
            className={classes.preview}
            onClick={() => {
              if (type === 'pdf') return history.push(`/pdf/${id}`)
              if (template.data?.version === 'v2') history.push(`/document/${id}/preview`) // remove after deprecating v2
              else history.push(`/${DOCUMENT_DIRECTORY}/${templateId}/${id}/preview`)
            }}
            disabled={isLoading}
            primary
          >
            <Eye active />
            {translate('preview')}
          </Button>
        ) : null}
        {editable ? (
          <Button
            className={classes.edit}
            onClick={() => history.push(`/${DOCUMENT_DIRECTORY}/${templateId}/${id}?step=configure`)}
            disabled={status === DOCUMENT_STATUSES.LOCKED}
          >
            {translate('edit')}
          </Button>
        ) : null}
        {version === 'v3' && preparedStatuses.includes(status!) && downloadable ? (
          <div
            className={classes.download}
            data-docx={user?.privacy.docxDownload ? '' : undefined}
            data-pdf={user?.privacy.pdfDownload ? '' : undefined}
          >
            {type !== 'pdf' && user?.privacy.docxDownload ? (
              <Button
                className={classes.docx}
                onClick={() => downloadRegularCallback('docx')}
                onClickBlur
                disabled={status === DOCUMENT_STATUSES.LOCKED || downloading.docx}
                loading={downloading.docx}
              >
                <Docx />
                <span>{translate('download')}</span>
              </Button>
            ) : null}
            {type === 'pdf' || user?.privacy.pdfDownload ? (
              <Button
                className={classes.pdf}
                onClick={() => (type === 'pdf' ? downloadPDFCallback() : downloadRegularCallback('pdf'))}
                onClickBlur
                disabled={status === DOCUMENT_STATUSES.LOCKED || downloading.pdf}
                loading={downloading.pdf}
              >
                <span>{translate('download')}</span>
                <Pdf />
              </Button>
            ) : null}
          </div>
        ) : null}
        {version === 'v2' ? (
          <Button
            onClick={() => {
              const filename = data?.name || 'v2 document download'
              const link = window.document.createElement('a')
              link.href = data?.downloadV2 ?? ''
              link.setAttribute('download', filename)
              window.document.body.appendChild(link)
              link.click()
              window.document.body.removeChild(link)
            }}
            primary
          >
            Download
          </Button>
        ) : null}
        {Object.keys(actions).length ? (
          <Dropdown hideCaret label={<Burger lines={3} />}>
            {dropdownActions}
          </Dropdown>
        ) : null}
      </div>
    )
  }
)

Actions.displayName = 'MainLayout-RightPane-Document-Actions'

export default Actions
